import React, {useEffect, useState} from 'react';
import MainMenu from './Header'
import {Header, Icon, Table} from 'semantic-ui-react'
import {Link} from '@reach/router'

import { DataStore } from '@aws-amplify/datastore';
import { Lead } from '../../models';

function LeadThumbnail({lead}){
    return <Table.Row>
    <Table.Cell>
      <Header as='h4'>
        <Header.Content>
        {lead.name}
        </Header.Content>
      </Header>
    </Table.Cell>
    <Table.Cell>{lead.phone}</Table.Cell>
    <Table.Cell className="mobile hidden">{lead.email}</Table.Cell>
    <Table.Cell collapsing className="mobile hidden">{lead.message}</Table.Cell>
    <Table.Cell className="nowrap"><Link  className='btn btn-primary'  to={'edit/'+lead.id}><i className="fas fa-pen"></i> <span className="mobile hidden">Edit</span></Link>
    <Link className='btn btn-error' style={{color:"red"}} to={'remove/'+lead.id}><i className="fa fa-times"></i> <span className="mobile hidden">Remove</span></Link></Table.Cell>
  </Table.Row>
}

export default function Leads({children, location}){
    const [leads, setLeads] = useState([])
    useEffect(() => {
        getLeads();
    },[])
    const getLeads = async()=>{
        const models = await DataStore.query(Lead);
        console.log(models)
        setLeads(models)
    }
    useEffect(() => {
      if(location.state && location.state.repull){
        getLeads();
      }
  }, [location.state])
    
    return <div  className="App page-wrap">
    <MainMenu />
    <div>
        <div className="fix-header" style={{height:83}}></div>
        <Header as='h2' icon textAlign='center'>
            <Icon name='users'  />
            <Header.Content>Leads</Header.Content>
            </Header>
       {children}

       {/*Display Services*/}
       <Table basic='very' padded striped unstackable>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell>Phone</Table.Cell>
            <Table.Cell className="mobile hidden">Email</Table.Cell>
            <Table.HeaderCell width={6} className="mobile hidden">Message</Table.HeaderCell>

            <Table.HeaderCell >Actions</Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
       {leads.map((l,i)=><LeadThumbnail key={"l_"+i} lead={l} />)}
       </Table.Body>
       </Table>
       </div>
    </div>

}