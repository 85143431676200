import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Project, Service } from '../../../models';
import {slugify} from '../../../utils'
import styled from 'styled-components';
import {includes, reject, find} from 'lodash';

const ToggleButton = styled(Button)
`
  box-shadow:none !important;
  margin:2px !important;
  border-color:#f0542c !important;
  &.ui.button.selected {
    background-color:#f0542c !important;
    color:white !important;
    box-shadow:none !important;
    border-color:white !important;
  }
`

export default function NewProject(){
  const navigate = useNavigate();
  const [name, setName] = useState("")

  const [description, setDescription] = useState("")
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([]);
  useEffect(() => {
      getServices();
  },[])
  const getServices = async()=>{
      const models = await DataStore.query(Service);
      console.log(models)
      setServices(models)
  }


  const createProject = async()=>{
    const project = await DataStore.save(
      new Project({
        "name": name,
        "slug": slugify(name),
        "description": description
      })
    );
    selectedServices.map(ss=>{
      // const projectservice = DataStore.save(
      //   new ProjectService({
      //     "projectID": project.id,
      //     "serviceID": ss,
      //   })
      // );
    })
    navigate('/dash/projects', { state: { repull:true } })
  }

  const toggleService = (id)=>{
    let service = find(services, {id});
    if(includes(selectedServices, id)){
      setSelectedServices(reject(selectedServices, function(s) {return s === id; }));
    } else {
      setSelectedServices([...selectedServices, service.id])
    }
  }
  
  const isSelected = (id)=>{
    if(includes(selectedServices, id)) return "selected"; else return "";
  }


    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/projects'>Cancel</Link></div>
      <Form>
    <Form.Field>
      <label>Name</label>
      <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter project name' />
    </Form.Field>
    <Form.Field>
      <label>Description</label>
      <input  value={description} onChange={(e)=>setDescription(e.target.value)} placeholder='Enter project description' />
    </Form.Field>
    <Form.Field>
    <label>Services Performed</label>
              {services.map((service)=> <ToggleButton color='orange' compact inverted key={"serv_"+service.id} onClick={()=>toggleService(service.id)} className={isSelected(service.id)}>{service.name}</ToggleButton>)}
              </Form.Field>
    <button className="btn btn-primary" onClick={()=>createProject()}>Create Project</button>
  </Form></div>
}