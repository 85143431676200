import React, {useEffect, useState} from 'react';
import MainMenu from './Header'
import {Header, Icon, Table} from 'semantic-ui-react'
import {Link} from '@reach/router'
import moment from 'moment'

import { DataStore } from '@aws-amplify/datastore';
import { Promo } from '../../models';

function PromoThumbnail({promo}){
    console.log(moment(promo.dateStart))
    return <Table.Row key={"p_"+promo.id}>
    <Table.Cell>{promo.text}</Table.Cell>
    <Table.Cell collapsing  className="mobile hidden">{moment(promo.dateStart).format('MMM Do YY')}</Table.Cell>
    <Table.Cell collapsing  className="mobile hidden">{moment(promo.dateEnd).format('MMM Do YY')}</Table.Cell>
    <Table.Cell className="nowrap">
        <Link  className='btn btn-primary'  to={'edit/'+promo.id}><i className="fas fa-pen"></i> <span className="mobile hidden">Edit</span></Link>
        <Link className='btn btn-error' style={{color:"red"}} to={'remove/'+promo.id}><i className="fa fa-times"></i> <span className="mobile hidden">Remove</span></Link>
        </Table.Cell>
  </Table.Row>
}

export default function Promos({children, location}){
    const [promos, setPromos] = useState([])
    useEffect(() => {
        getPromos();
    },[])
    const getPromos = async()=>{
        const models = await DataStore.query(Promo);
        console.log(models)
        setPromos(models)
    }
    useEffect(() => {
        if(location.state && location.state.repull){
            getPromos();
        }
    }, [location.state])
    
    
    return <div  className="App page-wrap">
    <MainMenu />
    <div>
        <div className="fix-header" style={{height:83}}></div>
        <Header as='h2' icon textAlign='center'>
            <Icon name='tag'  />
            <Header.Content>Promotions</Header.Content>
            </Header>
       {children}

       {/*Display Services*/}
       <Table basic='very' padded striped unstackable>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Promotion</Table.HeaderCell>
            <Table.HeaderCell collapsing className="mobile hidden">StartDate</Table.HeaderCell>
            <Table.HeaderCell collapsing className="mobile hidden">EndDate</Table.HeaderCell>

            <Table.HeaderCell >Actions</Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
       {promos.map((p,i)=><PromoThumbnail key={"p_"+i} promo={p} />)}
       </Table.Body>
       </Table>
       </div>
    </div>

}