import React, { useState, useEffect } from 'react';
import MenuHeader from '../components/MenuHeader'
import { useParams } from '@reach/router'
import Footer from '../components/Footer'
import { DataStore } from 'aws-amplify';
import { Article } from '../models';
import {getImageUrl} from '../components/SmartImage'

export default function ViewArticle() {
    const params = useParams();
    const [articleModel, setArticleModel] = useState();
    const [ready, setReady] = useState(false)
    useEffect(() => {
        getArticle();
    }, [])

    const getArticle = async () => {
        const toView = await DataStore.query(Article, a=> a.slug("eq", params.articleSlug));
        console.log(toView)
        if(toView.length > 0){
            setArticleModel(toView[0])
            setReady(true)
        }
    }
    if(!ready) return <></>;
    return <div className="App page-wrap">

        <MenuHeader />
        <div className="md-content">
            <div className="fix-header" style={{ height: 83 }}></div>

            <div className="hero__wrapper" style={{position:'relative', height:200, backgroundImage: `url(${getImageUrl(articleModel.imageKey, window.innerWidth)})` }}>
                <div className="hero__inner">
                    <div className="container">
                        <h1 className="hero__title">{articleModel.tagline1}</h1>
                        <p className="hero__desc">{articleModel.tagline2}</p>

                    </div>
                </div>
            </div>
            <div className="container md-section" dangerouslySetInnerHTML={{__html:(JSON.parse(articleModel.text)).html}}>
                
            </div>
        </div>
        <Footer />
    </div>
}