import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Promo } from '../../../models';
import {slugify} from '../../../utils'

export default function EditPromo(){
  const navigate = useNavigate();
  const params = useParams();
  const [promoModel, setPromoModel] = useState()
  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")
  const [text, setText] = useState("")
  const [ready, setReady] = useState(false)
  const onStartDateChange = (event, data) => setDateStart(data.value);
  const onEndDateChange = (event, data) => setDateEnd(data.value);
  useEffect(() =>{
    getPromo();
  },[])

  const getPromo = async() =>{
    const toEdit = await DataStore.query(Promo, params.promoId);
    setPromoModel(toEdit)
    setDateStart(new Date(toEdit.dateStart))
    setDateEnd(new Date(toEdit.dateEnd))
    setText(toEdit.text)
    setReady(true)
  }

  const updatePromo = async() =>{
    console.log(dateStart.toLocaleDateString(), dateEnd.toLocaleDateString())
    /* Models in DataStore are immutable. To update a record you must use the copyOf function
    to apply updates to the item’s fields rather than mutating the instance directly */
    await DataStore.save(Promo.copyOf(promoModel, item => {
        // Update the values on {item} variable to update DataStore entry
        item.dateStart = dateStart.toLocaleDateString();
        item.dateEnd = dateEnd.toLocaleDateString();
        item.text = text;
    }));
    navigate('/dash/promos', { state:{repull:true }})
  }

if(!ready)return <></>;
    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/promos'>Cancel</Link></div>
      <Form>
      <Form.Field>
      <label>Start Date of Promotion</label>
      <SemanticDatepicker value={dateStart} onChange={onStartDateChange} />
    </Form.Field>
    <Form.Field>
      <label>End Date of Promotion</label>
      <SemanticDatepicker value={dateEnd} onChange={onEndDateChange} />
    </Form.Field>
    <Form.Field>
      <label>Promotion Text</label>
      <TextArea  value={text} onChange={(e)=>setText(e.target.value)} placeholder='Enter promotion message' />
    </Form.Field>
    <button className="btn btn-primary" onClick={()=>updatePromo()}>Update Promo</button>
  </Form></div>
}