import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Lead } from '../../../models';
import {slugify} from '../../../utils'

export default function RemoveLead(){
  const navigate = useNavigate();
  const params = useParams();
  const [leadModel, setLeadModel] = useState()

  useEffect(() =>{
    getLead();
  },[])

  const getLead = async() =>{
    const toRemove = await DataStore.query(Lead, params.leadId);
    setLeadModel(toRemove)
  }

  const removeLead = async() =>{
    //const modelToDelete = await DataStore.query(Lead, 123456789);
    DataStore.delete(leadModel);


    navigate('/dash/leads', { state: { repull:true } })

  }
if(!leadModel) return <></>;
return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/leads'>Nah, I like 'em</Link></div>
      <Form>
    <div style={{margin:10, fontSize:20, marginTop:30}}><strong style={{color:'red'}}>Are you sure you want to remove the contact:</strong> {leadModel.name}  ?</div>
    <br/>
    <button className="btn btn-primary" onClick={()=>removeLead()}>Remove Lead</button>
  </Form></div>
}