import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Article } from '../../../models';
import {slugify} from '../../../utils'

export default function RemoveArticle(){
  const navigate = useNavigate();
  const params = useParams();
  const [articleModel, setArticleModel] = useState()

  useEffect(() =>{
    getArticle();
  },[])

  const getArticle = async() =>{
    const toRemove = await DataStore.query(Article, params.articleId);
    setArticleModel(toRemove)
  }

  const removeArticle = async() =>{
    //const modelToDelete = await DataStore.query(Article, 123456789);
    DataStore.delete(articleModel);


    navigate('/dash/articles', { state:{repull:true }})

  }
if(!articleModel) return <></>;
return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/articles'>Cancel</Link></div>
      <Form>
    <div style={{margin:10, fontSize:20, marginTop:30}}><strong style={{color:'red'}}>Are you sure you want to remove the article:</strong> {articleModel.tagline1}  ?</div>
    <br/>
    <button className="btn btn-primary" onClick={()=>removeArticle()}>Remove Article</button>
  </Form></div>
}