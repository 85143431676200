import React, {useState} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Service } from '../../../models';
import {slugify} from '../../../utils'

export default function NewService(){
  const navigate = useNavigate();
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  const createService = async()=>{
    await DataStore.save(
      new Service({
      "name": name,
      "slug": slugify(name),
      "description": description
    })
    );
    navigate('/dash/services', { state: { serviceChange:true } })
  }


    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/services'>Cancel</Link></div>
      <Form>
    <Form.Field>
      <label>Name</label>
      <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter service name' />
    </Form.Field>
    <Form.Field>
      <label>Description</label>
      <input  value={description} onChange={(e)=>setDescription(e.target.value)} placeholder='Enter service description' />
    </Form.Field>
    <button className="btn btn-primary" onClick={()=>createService()}>Create Service</button>
  </Form></div>
}