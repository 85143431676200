import React, {useEffect, useState} from 'react';
import MainMenu from './Header'

import {Link} from '@reach/router'
import {Header, Icon, Table} from 'semantic-ui-react'
import { DataStore } from '@aws-amplify/datastore';
import { Service } from '../../models';

function ServiceThumbnail({service}){
    return <Table.Row>
    <Table.Cell>
      <Header as='h4'>
        <Header.Content>
        {service.name}
        </Header.Content>
      </Header>
    </Table.Cell>
    <Table.Cell className="mobile hidden">{service.description}</Table.Cell>
    <Table.Cell>
        <Link  className='btn btn-primary'  to={'edit/'+service.id}><i className="fas fa-pen"></i> Edit</Link>
        <Link className='btn btn-error' style={{color:"red"}} to={'remove/'+service.id}><i className="fa fa-times"></i> Remove</Link>
        </Table.Cell>
  </Table.Row>
}

export default function Services({children, location}){
    const [services, setServices] = useState([])
    useEffect(() => {
        getServices();
    },[])

    useEffect(() => {
        if(location.state && location.state.serviceChange){
            getServices();
        }
    }, [location.state])
    const getServices = async()=>{
        const models = await DataStore.query(Service);
        console.log(models)
        setServices(models)
    }
    
    
    return <>
        
        <div  className="App page-wrap">
        <MainMenu />
        <div >
            <div className="fix-header" style={{height:83}}></div>
            <Header as='h2' icon textAlign='center'>
                <Icon name='settings'  />
                <Header.Content>Services</Header.Content>
                </Header>
           {children}

           {/*Display Services*/}
           <Table basic='very' padded striped unstackable>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={10}  className="mobile hidden" >Description</Table.HeaderCell>

                <Table.HeaderCell >Actions</Table.HeaderCell>
            </Table.Row>
            </Table.Header>

            <Table.Body>
           {services.map((s,i)=><ServiceThumbnail key={"s_"+i} service={s} />)}
           </Table.Body>
           </Table>
           </div>
        </div>
    </>

}