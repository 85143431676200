import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'
import { Link, useNavigate, useParams } from '@reach/router'
import { DataStore, Storage, Predicates } from 'aws-amplify';
import SingleImageUpload from '../../../components/SingleImageUpload'
import TextareaAutosize from "react-textarea-autosize";
import * as path from 'path'
import { Service, Image as ImageModel } from '../../../models';
import { slugify, getImageDims } from '../../../utils'

export default function EditService() {
  const navigate = useNavigate();
  const params = useParams();
  const [serviceModel, setServiceModel] = useState()
  const [uid, setUid] = useState("")
  const [image, setImage] = useState()
  const [imageChanged, setImageChanged] = useState(false)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  useEffect(() => {
    getService();
  }, [])

  const getService = async () => {
    const toEdit = await DataStore.query(Service, params.serviceId);
    const images = await DataStore.query(ImageModel, i => i.serviceID("eq", toEdit.id));
    const latestImage = images.at(-1);
    console.log("Latest Image", latestImage)
    console.log("Service to Edit", toEdit)
    setServiceModel(toEdit)
    setName(toEdit.name)
    setDescription(toEdit.description)
    setUid("services/"+toEdit.id)
    console.log("DATE", Date.now());
    if(latestImage) setImage(latestImage)
  }

  const storeImage = async({imageObj, key})=>{
    const imageModel = new ImageModel({
      width:imageObj.width,
      height:imageObj.height,
      imageKey: key,
      service: serviceModel,
      serviceID: serviceModel.id
    })
    console.log("IMAGE MODEL",imageModel)
    return await DataStore.save(imageModel)
  }

  const uploadImage = async({
    imageFile,
    onProgress,
    onSuccess,
    onError
  }) => {
    const file = imageFile;
    const fileext = path.extname(file.name);
    const fileVector = Date.now();
    const fullFilename = uid+"_"+fileVector+fileext;
    const response = await Storage.put(fullFilename, file, {
      contentType: file.type,
      resumable: true,
      completeCallback: async (event) => {
          console.log(`Successfully uploaded ${event.key}`);
          onSuccess(uid, {url:`https://acgiimagestorageprod-prod.s3.us-west-2.amazonaws.com/public/${fullFilename}`})
          
      },
      progressCallback: (progress) => {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          onProgress(uid, Math.round(progress.loaded / progress.total * 100));
      },
      errorCallback: (err) => {
          console.error('Unexpected error while uploading', err);
          onError(uid, {response:'Unexpected error while uploading: ' + err})
      }
  })
  console.log("response",response)
  
    return {
      abort() {
        // for cancels the request
      },
      key: `public/${fullFilename}`
    }
  }
  const onImageUploadComplete = (uid, {url}) =>{
    console.log("UPLOAD COMPLETE", uid, url)
  }

  const onImageUploadError = (uid, {response}) =>{
    console.log("UPLOAD ERROR", uid, response)
  }

  const onImageUploadProgress = (uid, progress) =>{
    console.log("UPLOAD PROGRESS", uid, progress)
  }

  const updateService = async () => {
    if(imageChanged){

      //Upload the new image and create new Image Model
      var {key} = await uploadImage({imageFile:image.file, onSuccess:onImageUploadComplete, onError:onImageUploadError, onProgress:onImageUploadProgress})
      var img = await storeImage({imageObj:image, key})
      console.log("THIS SHOULD BE AN IMAGEMODEL", img)
    }
    /* Models in DataStore are immutable. To update a record you must use the copyOf function
    to apply updates to the item’s fields rather than mutating the instance directly */
    var changedService = await DataStore.save(Service.copyOf(serviceModel, item => {
      // Update the values on {item} variable to update DataStore entry
      item.name = name;
      item.description = description;
      item.slug = slugify(name);
      if(imageChanged)      item.serviceImageId = img.id
    }));

    console.log(changedService)
    navigate('/dash/services', { state: { serviceChange:true } })
  }

  const onImageChange = async (imageList) => {
    console.log("Update ARGS", imageList)
    setImage(imageList[0])
    setImageChanged(true)
  }





  return <div className="workspace">

    <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/services'>Cancel</Link></div>
    <Form>
      <Form.Field>
        <label>Name</label>
        <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter service name' />
      </Form.Field>
      <Form.Field
              control={TextareaAutosize}
              label="Description"
              placeholder="Enter service description"
              onChange={e => setDescription(e.target.value)}
              value={description}
            />
      <Form.Field>
        <SingleImageUpload 
          onImageChange={onImageChange}
          initImage={image}
        />
      </Form.Field>
      <button className="btn btn-primary" onClick={() => updateService()}>Update Service</button>
    </Form></div>
}