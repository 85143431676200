import React, {useState} from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'
import {Link, useNavigate} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Lead } from '../../../models';
import {slugify} from '../../../utils'

export default function NewLead(){
  const navigate = useNavigate();
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [newsletter, setNewsletter] = useState(false)

  const createLead = async()=>{
    await DataStore.save(
      new Lead({
        name,
        phone,
        email,
        message,
        newsletter
      })
    );
    navigate('/dash/leads', { state: { repull:true } })
  }


    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/leads'>Cancel</Link></div>
      <Form>
    <Form.Field>
      <label>Name</label>
      <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter their name' />
    </Form.Field>
    <Form.Field>
      <label>Phone</label>
      <input  value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder='Enter their phone number' />
    </Form.Field>
    <Form.Field>
      <label>Email</label>
      <input  value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter their email address' />
    </Form.Field>
    <Form.Field>
      <label>Any message from/about lead</label>
      <TextArea  value={message} onChange={(e)=>setMessage(e.target.value)} placeholder='Enter message (optional)' />
    </Form.Field>
    <Form.Field>
      <label>Register them in newsletter</label>
      <Checkbox toggle checked={newsletter} onChange={(e)=>setNewsletter(!newsletter)} />
      
    </Form.Field>
    <button className="btn btn-primary" onClick={()=>createLead()}>Create Lead</button>
  </Form></div>
}