
import React, {useState} from 'react';
import {Icon, Header} from 'semantic-ui-react'
import { useNavigate } from "@reach/router"
import { Auth } from 'aws-amplify';
import '../../styles/header.scss';


function MenuHeader() {
    const [menuActive, setMenuActive] = useState(false)
    const [currentSection, setCurrentSection] = useState("services");
    const navigate = useNavigate();
    const signOut = ()=>{
        Auth.signOut();
        navigate('/')
    }
    return <header className="header header-fixheight header--fixed">
        <div className="container">
            <div className="header__inner">
                <div className="header-logo"><a href="/"><img src="https://acgi-homes.s3-us-west-2.amazonaws.com/logo+inverted.png" alt="" /></a></div>

                <nav className="raising-nav">

                    <ul className={"raising-menu " + (menuActive ? " active" :"")}>
                        <li className={currentSection === "services"?"current":""}><a href="/dash/services">Services</a>
                        </li>
                        <li className={currentSection === "projects"?"current":""}><a href="/dash/projects">Projects</a>
                        </li>
                        <li className={currentSection === "leads"?"current":""}><a href="/dash/leads">Leads</a>
                        </li>
                        <li className={currentSection === "reviews"?"current":""}><a href="/dash/reviews">Reviews</a>
                        </li>
                        <li className={currentSection === "promos"?"current":""}><a href="/dash/promos">Promotions</a>
                        </li>
                        <li className={currentSection === "articles"?"current":""}><a href="/dash/articles">Articles</a>
                        </li>
                    </ul>

                    <div className="navbar-toggle" onClick={()=>setMenuActive(!menuActive)}><i className="fa fa-bars"></i></div>
                </nav>

                <div style={{fontSize:22,display:'flex', flexDirection:'row', paddingRight:40}}>
                    <button style={{fontSize:16, backgroundColor:'#f0542c', color:'white', padding:10, border:'none'}} onClick={signOut}>Sign Out</button>
                </div>
            </div>
        </div>
    </header>

}

export default MenuHeader;