import React, {useState} from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'
import {Link, useNavigate} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import RichTextEditor from 'react-rte';
import { Article } from '../../../models';
import {slugify} from '../../../utils'

export default function NewArticle(){
  const navigate = useNavigate();
  const [tagline1, setTagline1] = useState("")
  const [tagline2, setTagline2] = useState("")
  const [content, setContent] = useState(RichTextEditor.createEmptyValue())
    const [contentToSave, setContentToSave] = useState("")
  const [homepage, setHomepage] = useState(false)
  const [imageUrl, setImageUrl] = useState("")

  const createArticle = async()=>{
    await DataStore.save(
      new Article({
        tagline1,
        tagline2,
        text:JSON.stringify({ html: contentToSave }),
        homepage,
        imageUrl,
        slug:slugify(tagline1)
      })
    );
    navigate('/dash/articles', { state:{repull:true }})
  }
  const onRTEChange = (value) => {
    setContent(value);
        setContentToSave(value.toString('html'));
    
  };

    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/articles'>Cancel</Link></div>
      <Form>
    <Form.Field>
      <label>Tagline 1 - Title</label>
      <input value={tagline1} onChange={(e)=>setTagline1(e.target.value)} placeholder='Enter subject of article' />
    </Form.Field>
    <Form.Field>
      <label>Tagline 2 - Subtitle</label>
      <input  value={tagline2} onChange={(e)=>setTagline2(e.target.value)} placeholder='Enter subtitle of article' />
    </Form.Field>
    <Form.Field>
      <label>Content</label>
      <RichTextEditor
        value={content}
        onChange={onRTEChange}
      />
    </Form.Field>
    <Form.Field>
      <label>Image thumbnail (url:https://...)</label>
      <TextArea  value={imageUrl} onChange={(e)=>setImageUrl(e.target.value)} placeholder='Enter url for thumbnail (try unsplash)' />
    </Form.Field>
    <Form.Field>
      <label>Should the article be on the homepage</label>
      <Checkbox toggle checked={homepage} onChange={(e)=>setHomepage(!homepage)} />
      
    </Form.Field>
    <button className="btn btn-primary" onClick={()=>createArticle()}>Create Article</button>
  </Form></div>
}