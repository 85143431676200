import React, { useState } from "react";
import { Checkbox, Form, TextArea } from 'semantic-ui-react'
import { DataStore } from '@aws-amplify/datastore';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Lead,  ProjectRequest } from '../models';
import { slugify } from '../utils'
import {includes, reject, find} from 'lodash';

export default function ContactUs() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [address, setAddress] = useState("");
    const [quote, setQuote] = useState(false);
    const [newsletter, setNewsletter] = useState(true)
    const [contactPref, setContactPref] = useState(["phone","email","text"]);
    const [loanConditions, setLoanConditions] = useState(false);
    const [budget, setBudget] = useState(0);
    const [projectStart, setProjectStart] = useState("");
    const [projectEnd, setProjectEnd] = useState("");
    const [scheduleFlex, setScheduleFlex] = useState(true);
    const [contactSent, setContactSent] = useState(false)

    const onStartDateChange = (event, data) => setProjectStart(data.value);
    const onEndDateChange = (event, data) => setProjectEnd(data.value);


    const toggleContactPref = (contactType)=>{
        if(includes(contactPref, contactType)){
            setContactPref(reject(contactPref, function(s) {return s === contactType; }));
        } else {
            setContactPref([...contactPref, contactType])
        }
      }
      
      const isChecked = (contactType)=>{
        return includes(contactPref, contactType)
      }

    const createLead = async () => {
        if(quote){
            //Create a ProjectRequest
            await DataStore.save(
                  new ProjectRequest({
                    name,
                    phone,
                    email,
                    message,
                    contactPreference: contactPref.join(','),
                    data:JSON.stringify({
                        loanConditions,
                        budget,
                        address,
                        projectStart,
                        projectEnd,
                        scheduleFlex
                    })
                  })
                );

        } else {
            //Create a Lead
            await DataStore.save(
                new Lead({
                    name,
                    phone,
                    email,
                    message,
                    newsletter
                })
            );
        }
        setContactSent(true)
    }
    if(contactSent) return <div className="container md-content-center md-text-center" style={{fontSize:22}}>{quote? "Your Project Request has been sent.  We will get back to you as soon as possible.  Thank you!":"Your Message has been sent.  Thank you!"}</div>
    return <><Form>
        <Form.Group widths='equal'>
            <Form.Field required>
                <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Your Full Name' />
            </Form.Field>
            <Form.Field required>
                <input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Phone / Mobile' />
            </Form.Field>
            <Form.Field required>
                <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
            </Form.Field>
        </Form.Group>
        <Form.Field>
            <Checkbox checked={quote} label='I would like to request a quote.' onChange={(e) => setQuote(!quote)} />
        </Form.Field>
        {quote ? <>
            <Form.Field>
            <label>How would you like ACGI to contact you?</label>
                <Checkbox style={{margin:5}} checked={isChecked("phone")} label='Phone' onChange={() => toggleContactPref("phone")} />
                <Checkbox style={{margin:5}} checked={isChecked("email")} label='Email' onChange={() => toggleContactPref("email")} />
                <Checkbox style={{margin:5}} checked={isChecked("text")} label='Text' onChange={() => toggleContactPref("text")} />
            </Form.Field>
            <Form.Field >
                <label>Please give the address where the project will take place?</label>
                <input value={address} onChange={(e) => setAddress(e.target.value)} placeholder='Enter address' />
            </Form.Field>
            <Form.Field >
                <label>What is the anticipated budget range for your project?</label>
                <input value={budget} onChange={(e) => setBudget(e.target.value)} placeholder='Enter budget range (i.e. 3-5k)' />
            </Form.Field>
            <Form.Field >
                <label>What is your timeline for your project(s)?</label>
                <SemanticDatepicker onChange={onStartDateChange} placeholder="Start Date" /><span style={{marginLeft:10}} />
                <SemanticDatepicker  onChange={onEndDateChange}  placeholder="End Date"/>
                <br/>
                <Checkbox checked={scheduleFlex} label="I'm flexible" onChange={() => setScheduleFlex(!scheduleFlex)} />
            </Form.Field>
            <Form.Field>
                <label>Briefly tell us about the work you want performed.</label>
                <TextArea value={message} style={{ height: 150 }} onChange={(e) => setMessage(e.target.value)} placeholder='Enter project requests' />
            </Form.Field>
            <Form.Field>
                <Checkbox checked={loanConditions} label='I have loan conditions or buyer/seller contingencies, obligations or deadlines for this project.' onChange={() => setLoanConditions(!loanConditions)} />
            </Form.Field>
        </>
            :
            <Form.Field>
                <TextArea value={message} style={{ height: 150 }} onChange={(e) => setMessage(e.target.value)} placeholder='Enter message (optional)' />
            </Form.Field>

        }
        <Form.Field>
            <Checkbox checked={newsletter} label="I would like to receive the ACGI Homes newsletter / promotions." onChange={(e) => setNewsletter(!newsletter)} />

        </Form.Field>
        <a className="btn btn btn-primary btn-custom" onClick={() => createLead()}>{quote? "Send Request": "Send Message"}</a>
    </Form>
    <br/>
    <br/>
        {quote && <div className="subtext">
            <p>★ Upon receiving this document from you via mail or email, we will set an appointment with you to come take measurements &amp;discuss your project with you at your home in further detail.</p>
            <p>★ ACGI will get you a price on site &amp; or by email &amp; if you are agreeable to the contract terms, we will fill out contracts &amp; the ccb notices at that time. Scheduling is based on a 1st come, 1st serve basis.</p>
            <p>★ ACGI requires 50% of the total labor &amp; full materials amount upon signing of contract. 50% of remaining labor paid upon
                completion of the project. Progress Payments of 1/2 down &amp; full materials to start project &amp; 1/4 of the remaining labor amount x 4
                payments are required for all projects that exceed 1 week. </p>
            <p>★ Change orders are photographed (if needed) emailed, documented &amp; sent to your email for a signature, approval &amp; check or
                credit card payment.</p>
            <p>★ ACGI may request that you take 1-4 pictures of the area, house or section where the construction will take place &amp; or take
                measurements of a room &amp; email the data to the address below.</p>
        </div>}
    </>
}