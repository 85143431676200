import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Lead } from '../../../models';
import {slugify} from '../../../utils'

export default function EditLead(){
  const navigate = useNavigate();
  const params = useParams();
  const [leadModel, setLeadModel] = useState()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [newsletter, setNewsletter] = useState(false)

  useEffect(() =>{
    getLead();
  },[])

  const getLead = async() =>{
    const toEdit = await DataStore.query(Lead, params.leadId);
    setLeadModel(toEdit)
    setName(toEdit.name)
    setPhone(toEdit.phone)
    setEmail(toEdit.email)
    setMessage(toEdit.message)
    setNewsletter(toEdit.newsletter)
  }

  const updateLead = async() =>{
    /* Models in DataStore are immutable. To update a record you must use the copyOf function
    to apply updates to the item’s fields rather than mutating the instance directly */
    await DataStore.save(Lead.copyOf(leadModel, item => {
        // Update the values on {item} variable to update DataStore entry
        item.name = name;
        item.phone = phone;
        item.email = email;
        item.message = message;
        item.newsletter = newsletter;
    }));
    navigate('/dash/leads', { state: { repull:true } })
  }


    return  <div  className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/leads'>Cancel</Link></div>
      <Form>
    <Form.Field>
      <label>Name</label>
      <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter their name' />
    </Form.Field>
    <Form.Field>
      <label>Phone</label>
      <input  value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder='Enter their phone number' />
    </Form.Field>
    <Form.Field>
      <label>Email</label>
      <input  value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter their email address' />
    </Form.Field>
    <Form.Field>
      <label>Any message from/about lead</label>
      <TextArea  value={message} onChange={(e)=>setMessage(e.target.value)} placeholder='Enter message (optional)' />
    </Form.Field>
    <Form.Field>
      <label>Register them in newsletter</label>
      <Checkbox toggle checked={newsletter} onChange={(e)=>setNewsletter(!newsletter)} />
      
    </Form.Field>
    <button className="btn btn-primary" onClick={()=>updateLead()}>Update Lead</button>
  </Form></div>
}