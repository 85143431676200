import React from "react";
import { Link } from '@reach/router'
import useStore from '../store/useStore'
import '../styles/footer.scss';


function Footer() {

    const services = useStore(state => state.services);

    return <footer className="footer-01 md-skin-dark">
        <div className="footer-01__widget">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3 ">


                        <section className="widget">


                            <div className="sec-title">
                                <h2 className="sec-title__title">Owner</h2><span className="sec-title__divider"></span>
                            </div>
                            <p>Bobby Andruszko is a PSU Communications graduate, 3rd generation Oregon Contractor with 12 years in the industry.<br/>
                            Bobby was in the trades before starting a business.  

                            <br/>
                            <br/>
                            Night owl extrodinare...
                            </p>
                        </section>

                    </div>
                    <div className="col-md-6 col-lg-3 ">


                        <section className="widget">


                            <div className="sec-title">
                                <h2 className="sec-title__title">Service Tags</h2><span className="sec-title__divider"></span>
                            </div>



                            <div className="widget-tag">
                                {services.map(s => <Link key={"smf_"+s.id} to={"/service/" + s.slug}>{s.name}</Link>)}
                            </div>

                        </section>

                    </div>
                    <div className="col-md-6 col-lg-3 ">


                        <section className="widget">


                            <div className="sec-title">
                                <h2 className="sec-title__title">Awards</h2><span className="sec-title__divider"></span>
                            </div>



                            <div className="widget-gallery">
                                <div className="reward-image" style={{ width: 80 }}><img className="sc-bZQynM iBAInr" src="https://acgiimagestorageprod-prod.s3.us-west-2.amazonaws.com/public/site/2019servicehouzz.jpg" alt="Best of Houzz 2019" /></div>
                                <div className="reward-image" style={{ width: 80 }}><img className="sc-bZQynM iBAInr" src="https://acgiimagestorageprod-prod.s3.us-west-2.amazonaws.com/public/site/2020servicehouzz.png" alt="Best of Houzz 2020" /></div>
                                <div className="reward-image" style={{ width: 80 }}><img style={{ width: 80 }} className="sc-bZQynM iBAInr" src="https://sk.hzcdn.com/assets/en-US/20210923144348/jpics/badge_54_7.png" alt="Best of Houzz 2021" /></div>
                                <div className="reward-image" style={{ width: 80 }}><img style={{ width: 80 }} className="sc-bZQynM iBAInr" src="https://acgiimagestorageprod-prod.s3.us-west-2.amazonaws.com/public/site/EPA-Lead-Safe-Certified.jpg" alt="Certified EPA Lead Safe" /></div>
                                <div className="reward-image" style={{ width: 80 }}><img style={{ width: 150}} width="150" height="160" src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/or_beaverton_painting_2021.svg" alt="Best Painters in Beaverton" /></div>
                            </div>

                        </section>

                    </div>
                    <div className="col-lg-3 ">


                        <section className="widget">


                            <div className="sec-title">
                                <h2 className="sec-title__title">Contact &amp; Working hours</h2><span className="sec-title__divider"></span>
                            </div>

                            <p>We will do our best to work around your schedule.</p>


                            <div>
                                <div className="widget-contact__item"><span className="widget-contact__title">Tel:</span>
                                    <p className="widget-contact__text"><a href="tel:5034311194">503.431.1194</a></p>
                                </div>
                                <div className="widget-contact__item"><span className="widget-contact__title">email:</span>
                                    <p className="widget-contact__text"><a href="mailto:info@acgihomes.com">info@acgihomes.com</a></p>
                                </div>
                                <div className="widget-contact__item"><span className="widget-contact__title">Working Hours:</span>
                                    <p className="widget-contact__text">Mon - Sat: 9:00 am - 7:00pm</p>
                                </div>
                            </div>

                        </section>

                    </div>
                </div>
            </div>
        </div>


        <div className="copyright-01 md-text-center">
            <div className="container">
                <p className="copyright-01__copy">2021 &copy; Copyright ACGI incorporated. All rights Reserved.</p>
            </div>
            <div className="update">
                <Link to="dash" ><i className="fa fa-laptop-house"></i></Link>
            </div>
        </div>

    </footer>
}

export default Footer;