import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Review } from '../../../models';
import {slugify} from '../../../utils'

export default function RemoveReview(){
  const navigate = useNavigate();
  const params = useParams();
  const [reviewModel, setReviewModel] = useState()

  useEffect(() =>{
    getReview();
  },[])

  const getReview = async() =>{
    const toRemove = await DataStore.query(Review, params.reviewId);
    setReviewModel(toRemove)
  }

  const removeReview = async() =>{
    //const modelToDelete = await DataStore.query(Review, 123456789);
    DataStore.delete(reviewModel);


    navigate('/dash/reviews', {state:{repull:true}})

  }
if(!reviewModel) return <></>;
return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/reviews'>This is scary, go back</Link></div>
      <Form>
    <div style={{margin:10, fontSize:20, marginTop:30}}><strong style={{color:'red'}}>Are you sure you want to remove the review:</strong> {reviewModel.author}  ?</div>
    <div>This will remove all connections this review has with any services, projects.</div>
    <br/>
    <button className="btn btn-primary" onClick={()=>removeReview()}>Yeah I have better ones to show</button>
  </Form></div>
}