import produce from 'immer';
import { nanoid } from 'nanoid';
import { DataStore } from 'aws-amplify';
import { Service, Project, Image as ImageModel, Article } from '../models';
import { each, filter, map, keyBy, isEmpty, compact, includes, find } from 'lodash';


const servicesStoreSlice = (set, get) => ({
  projects:[],
  services: [],
  articles:[],
  loadServices:async()=>{
    console.log("LOADING SERVICES")
    var servicesData = await DataStore.query(Service);
    //console.log("Services", servicesData)
    let serviceList = [];
    await Promise.all(servicesData.map(async s=>{
      
      let service = await DataStore.query(Service, s.id);
      const images = await DataStore.query(ImageModel, i => i.serviceID("eq", s.id));
      const latestImage = images.at(-1);
      let sv = {...service, image:latestImage};
      serviceList.push(sv);
      console.log("service after modification", sv);
    }))

    set(produce(state=>{
      state.services = serviceList;
    }))

    //Return state results
    // return get().services;
  }, 
  getServices:()=>{
    return get().services;
  },

  loadProjects:async()=>{
    console.log("LOADING PROJECTS")
    var projectData = await DataStore.query(Project);
    //console.log("Services", servicesData)
    let projectList = [];
    await Promise.all(projectData.map(async p=>{
      
      //let project = await DataStore.query(Service, s.id);
      const images = await DataStore.query(ImageModel, i => i.projectID("eq", p.id));
      const latestImage = images.at(-1);
      let pv = {...p, image:latestImage};
      projectList.push(pv);
      console.log("project after modification", pv);
    }))

    set(produce(state=>{
      state.projects = projectList;
    }))

    //Return state results
    // return get().services;
  }, 

  loadArticles:async()=>{
    console.log("LOADING Articles")
    var articleData = await DataStore.query(Article);
    //console.log("Services", servicesData)
    let articleList = [];
    await Promise.all(articleData.map(async p=>{
      
      //let article = await DataStore.query(Service, s.id);
      const images = await DataStore.query(ImageModel, i => i.articleID("eq", p.id));
      const latestImage = images.at(-1);
      let pv = {...p, image:latestImage};
      articleList.push(pv);
      console.log("article after modification", pv);
    }))

    set(produce(state=>{
      state.articles = articleList;
    }))

    //Return state results
    // return get().services;
  }, 
});




export default servicesStoreSlice;