import React,{useState, useEffect} from "react";
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import {Link} from '@reach/router'
import { DataStore } from 'aws-amplify';
import { Article } from '../models';
import useStore from '../store/useStore'
import {getImageUrl} from '../components/SmartImage'
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'


import ScrollHelper from '../components/ScrollHelper'
import '../styles/hero.scss';

function Hero() {
    const [articles, loadArticles] = useStore(state=>[state.articles, state.loadArticles])
    const [ready, setReady] = useState(false);

    useEffect(() => {loadContent()},[]);

    const loadContent = async() =>{
        await loadArticles();
        setReady(true)
    }
    if(!ready) return <></>;
    return <ScrollHelper identifier="home"><div className="hero">

        <Swiper className="swiper"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={30}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}>
       {articles.map((a,i)=><SwiperSlide key={"sah_"+a.id} className="hero__wrapper" style={{backgroundImage:`url(${getImageUrl(a.imageKey, window.innerWidth)})`}}>
       <div className="hero__inner">
           <div className="container">
               <h1 className="hero__title">{a.tagline1}</h1>
               <p className="hero__desc">{a.tagline2}</p>


               <Link to={"article/"+a.slug} className="btn btn-primary">Read More</Link>


           </div>
       </div>
</SwiperSlide>
       )}
            
        </Swiper>
    </div>
    </ScrollHelper>
}

export default Hero;

/*
<Swiper cssMode={true} navigation={true} pagination={true} mousewheel={true} keyboard={true} className="mySwiper">
            
        </Swiper>

        <div className="swiper swiper-container">
            <div className="swiper-wrapper">
                
                
                <div className="hero__wrapper" style={{backgroundImage:`url(${Slider4})`}}>
                    <div className="hero__inner">
                        <div className="container">
                            <h1 className="hero__title">How to Create and Manage SVG Sprites</h1>
                            <p className="hero__desc">Nam suscipit nisi risus, et porttitor metus molestie a. Phasellus id quam id turpis suscipit</p>


                            <a className="btn btn-primary" href="#">button
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div className="swiper-pagination-custom"></div>
            <div className="swiper-button-custom">
                <div className="swiper-button-prev-custom"><i className="fa fa-angle-left"></i></div>
                <div className="swiper-button-next-custom"><i className="fa fa-angle-right"></i></div>
            </div>
        </div>
*/