import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore, Predicates } from '@aws-amplify/datastore';
import { Project,  Service } from '../../../models';
import {slugify} from '../../../utils'

import styled from 'styled-components';
import {includes, reject, find} from 'lodash';

const ToggleButton = styled(Button)
`
  box-shadow:none !important;
  margin:2px !important;
  border-color:#f0542c !important;
  &.ui.button.selected {
    background-color:#f0542c !important;
    color:white !important;
    box-shadow:none !important;
    border-color:white !important;
  }
`
export default function EditProject(){
  const navigate = useNavigate();
  const params = useParams();
  const [projectModel, setProjectModel] = useState()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() =>{
    getProject();
    getServices();
  },[])

  const getServices = async()=>{
    const models = await DataStore.query(Service);
    setServices(models)
}

  const getProject = async() =>{
    const toEdit = await DataStore.query(Project, params.projectId);
    setProjectModel(toEdit)
    setName(toEdit.name)
    setDescription(toEdit.description)
    // const selServices = (await DataStore.query(ProjectService))                
    //             .filter(ps => ps.project.id === params.projectId)
    //             .map(ps => {console.log(ps);return ps.service.id})
    // setSelectedServices(selServices)
    // console.log(selServices)
  }

  const updateProject = async() =>{
    /* Models in DataStore are immutable. To update a record you must use the copyOf function
    to apply updates to the item’s fields rather than mutating the instance directly */
    await DataStore.save(Project.copyOf(projectModel, item => {
        // Update the values on {item} variable to update DataStore entry
        item.name = name;
        item.description = description;
        item.slug = slugify(name)
    }));
    navigate('/dash/projects', { state: { repull:true } })
  }
  const toggleService = (id)=>{
    let service = find(services, {id});
    if(includes(selectedServices, id)){
      setSelectedServices(reject(selectedServices, function(s) {return s === id; }));
    } else {
      setSelectedServices([...selectedServices, service.id])
    }
  }
  
  const isSelected = (id)=>{
    if(includes(selectedServices, id)) return "selected"; else return "";
  }

    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/projects'>Cancel</Link></div>
      <Form>
    <Form.Field>
      <label>Name</label>
      <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter project name' />
    </Form.Field>
    <Form.Field>
      <label>Description</label>
      <input  value={description} onChange={(e)=>setDescription(e.target.value)} placeholder='Enter project description' />
    </Form.Field>
    <Form.Field>
    <label>Services Performed</label>
              {services.map((service)=> <ToggleButton color='orange' compact inverted key={"serv_"+service.id} onClick={()=>toggleService(service.id)} className={isSelected(service.id)}>{service.name}</ToggleButton>)}
              </Form.Field>
    <button className="btn btn-primary" onClick={()=>updateProject()}>Update Project</button>
  </Form></div>
}