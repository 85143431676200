import React from 'react';
import { Hub, DataStore, Predicates } from 'aws-amplify';

export function useSyncDataStore() {
  const [isSyncComplete, setSyncComplete] = React.useState(false);

  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await DataStore.start();
      } catch (e) {
        console.warn(e);
      } finally {
        const removeListener = Hub.listen("datastore", async (data) => {
          const { payload } = data;
          if (payload.event === "ready") {
            console.log('READY', payload)
            setSyncComplete(true)
            removeListener();
          }
        });        
      }
    }
    loadResourcesAndDataAsync();
  }, []);
  return isSyncComplete
}




export function slugify(text)
{
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}


export const prepareImage = async(imageObj)=>new Promise(resolve => {

  if(imageObj === undefined) resolve({})
  const fileAsDataURL = window.URL.createObjectURL(imageObj.file)
  const img = new Image()
  img.onload = () => {
    resolve({
      ...imageObj,
      height: img.height,
      width: img.width
    })
  }
  img.src = fileAsDataURL
})

export const getImageDims = async(file)=>new Promise(resolve => {

  if(file === undefined) resolve({})
  const fileAsDataURL = window.URL.createObjectURL(file)
  const img = new Image()
  img.onload = () => {
    resolve({
      height: img.height,
      width: img.width
    })
  }
  img.src = fileAsDataURL
})