import React from 'react';
import {Visibility} from 'semantic-ui-react'
import useStore from "../store/useStore";

export default function ScrollHelper({children, identifier}){
    const setCurrentSection = useStore(state => state.setCurrentSection);

    const handleUpdate = (e, { calculations:{passing, percentagePassed, topVisible} }) => {
       // console.log(identifier, percentagePassed, passing)
        if(percentagePassed < .05 && topVisible)setCurrentSection(identifier);
    }
    return <Visibility onUpdate={handleUpdate} id={identifier}>
        {children}
    </Visibility>
}