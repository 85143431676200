import React from 'react';
import ImageUploading from 'react-images-uploading';
import {getImageUrl} from './SmartImage'
import {prepareImage} from '../utils'

const resolveImageDimensions = async(imageList)=>Promise.all(imageList.map((image) => prepareImage(image)))


export default function SingleImageUpload({onImageChange, initImage}) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = async(imageList, addUpdateIndex) => {
    // data for submit
    imageList = await resolveImageDimensions(imageList)
    console.log(imageList);
    
    setImages(imageList);
    onImageChange(imageList);
  };

  return (
    <div className="image-uploader">
      <ImageUploading
        multiple={false}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              className="btn btn-primary"
              onClick={images.length > 0?onImageUpdate: onImageUpload}
            >
              {images.length > 0? "Update":"Add Image"}
            </button>
            {imageList.length > 0? imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                
              </div>
            )): <>{initImage && <div className="image-item"><img src={getImageUrl(initImage.imageKey)} alt="" width="100" /></div>}</>}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}