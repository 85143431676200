import React from 'react';

import MenuHeader from '../components/MenuHeader'
import Hero from '../containers/Hero';
import QuoteBar from '../containers/QuoteBar';
import AboutSection from '../containers/AboutSection';
import Services from '../containers/Services';
import Gallery from '../containers/Gallery';
import Testimonials from '../containers/Testimonials'
import ContactUs from '../containers/ContactUs';
import Footer from '../components/Footer';



function Main() {
  return (
    <div className="App page-wrap">
      
      <MenuHeader />
      <div className="md-content">
        <div className="fix-header" style={{height:83}}></div>
        <Hero />
        <QuoteBar />
        <AboutSection />
        <Services />
        <Gallery />
        <Testimonials />
        <ContactUs />
      </div>
      
      <Footer />
    </div>
  );
}

export default Main;
