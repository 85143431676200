import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'

import { Link, useNavigate, useParams } from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import RichTextEditor from 'react-rte';
import { Article } from '../../../models';
import { slugify } from '../../../utils'

export default function NewArticle() {
    const navigate = useNavigate();
    const params = useParams();
    const [articleModel, setArticleModel] = useState()
    const [tagline1, setTagline1] = useState("")
    const [tagline2, setTagline2] = useState("")
    const [content, setContent] = useState(RichTextEditor.createEmptyValue())
    const [contentToSave, setContentToSave] = useState("")
    const [homepage, setHomepage] = useState(false)
    const [imageUrl, setImageUrl] = useState("")

    useEffect(() => {
        getArticle();
    }, [])

    const getArticle = async () => {
        const toEdit = await DataStore.query(Article, params.articleId);
        setArticleModel(toEdit)
        setTagline1(toEdit.tagline1)
        setTagline2(toEdit.tagline2)
        if (toEdit.text) {

            setContent(RichTextEditor.createValueFromString((JSON.parse(toEdit.text)).html, 'html'));
            setContentToSave((JSON.parse(toEdit.text)).html)
        } else {
            setContent(RichTextEditor.createEmptyValue())
        }
        setHomepage(toEdit.homepage)
        setImageUrl(toEdit.imageUrl)
    }

    const updateArticle = async () => {
        console.log("converting content:", contentToSave)
        /* Models in DataStore are immutable. To update a record you must use the copyOf function
        to apply updates to the item’s fields rather than mutating the instance directly */
        await DataStore.save(Article.copyOf(articleModel, item => {
            // Update the values on {item} variable to update DataStore entry
            item.tagline1 = tagline1;
            item.tagline2 = tagline2;
            item.text = JSON.stringify({ html: contentToSave });
            item.homepage = homepage;
            item.imageUrl = imageUrl;
            item.slug = slugify(tagline1)
        }));
        navigate('/dash/articles', { state:{repull:true }})
    }

    const onRTEChange = (value) => {
        console.log(value.toString('html'))
        setContent(value);
        setContentToSave(value.toString('html'));

    };

    return <div className="workspace">

        <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/articles'>Cancel</Link></div>
        <Form>
            <Form.Field>
                <label>Tagline 1 - Title</label>
                <input value={tagline1} onChange={(e) => setTagline1(e.target.value)} placeholder='Enter subject of article' />
            </Form.Field>
            <Form.Field>
                <label>Tagline 2 - Subtitle</label>
                <input value={tagline2} onChange={(e) => setTagline2(e.target.value)} placeholder='Enter subtitle of article' />
            </Form.Field>
            <Form.Field>
                <label>Content</label>
                <RichTextEditor
                    value={content}
                    onChange={onRTEChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Image thumbnail (url:https://...)</label>
                <TextArea value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} placeholder='Enter url for thumbnail (try unsplash)' />
            </Form.Field>
            <Form.Field>
                <label>Should the article be on the homepage</label>
                <Checkbox toggle checked={homepage} onChange={(e) => setHomepage(!homepage)} />

            </Form.Field>
            <button className="btn btn-primary" onClick={() => updateArticle()}>Update Article</button>
        </Form></div>
}