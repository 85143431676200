import React, { useState } from "react";
import { Visibility } from 'semantic-ui-react'
import { decode as atob, encode as btoa } from 'base-64'
import styled, { keyframes } from 'styled-components';

export function getImageUrl (imageKey, size=400, fit=false){
   
         var CloudFrontUrl = "https://d2xyzyk661n2vc.cloudfront.net";
        
        const imageRequest = JSON.stringify({
            "bucket": "acgiimagestorageprod-prod",
            "key": imageKey,
            "edits": {
                "resize": {
                    "width": size || 400,
                    "fit": fit || "cover"
                }
            }
        })
        const url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
       
        return url;
}

export default function SmartImage({ imageKey, size, fit, background, className }) {
    const [showImage, setShowImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState();
    const [error, setError] = useState(false);

    const getImage = ()=>{
        if (error) return null;
         var CloudFrontUrl = "https://d2xyzyk661n2vc.cloudfront.net";
        
        const imageRequest = JSON.stringify({
            "bucket": "acgiimagestorageprod-prod",
            "key": imageKey,
            "edits": {
                "resize": {
                    "width": size || 400,
                    "fit": fit || "cover"
                }
            }
        })
        const url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
        setUrl(url);
        return url;
    }

    const loadImage = ()=>{
        setLoading(true)
        var image = new Image();
        image.src = getImage();
        image.onload = setLoading(false);
        image.onerror = ()=>{setLoading(false); setError(true)};
        setShowImage(true);
    }

    const showState = () =>{
        if (error) return <LoaderBackground  className={className}>Error Loading Image</LoaderBackground>;
        if (loading) return <LoaderBackground  className={className}>Loading Image</LoaderBackground>;
        else {
            if (background) return <LoaderBackground className={className}><BackgroundImage  imageurl={url} /></LoaderBackground>;
            else return <Img className={className} src={url} />
        }
    }

    if (showImage) return showState();

    return <Visibility as="div" className="card-image-fill" onOnScreen={loadImage} fireOnMount={true}>
                    <LoaderBackground>Loading Image</LoaderBackground>
                </Visibility>
}


/*
STYLES
------------------------------------------
*/
const pulse = keyframes `
 0% {
      opacity: 1;
    }
     50% {
      opacity: 0.4;
    }
`

const fadeIn = keyframes `
    from { opacity: 0; }
    to   { opacity: 1; }
`

const BackgroundImage = styled.div `    
    width: 100%;
    height: 100%;
    background-image: url(${props => props.imageurl});
    background-size: cover !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    display:block;
    position:absolute;
    animation:${fadeIn} .5s;
    z-index:1;
    &.top {
        background-position: 50% 0% !important;
    }
`

const LoaderBackground = styled.div `
    width:100%;
    height:100%;
    background-color:#dfdfdf;
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    position:relative;
    top:0;
    left:0;
    svg{
        font-size:72px;
        color:white;
        flex:1;
        animation: ${pulse} 1.2s infinite;
                 animation-fill-mode: both;
    }
`

const ImageWrapper = styled.div `
position:relative;
    padding-bottom:67%;
    display:block;
    border-radius:2px;
    overflow:hidden;
    
    `

const Img = styled.img `
position:relative;
z-index:1;
animation:${fadeIn} .5s;
`