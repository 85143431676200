import React from 'react';

import Main from './pages/Main'
import Article from './pages/Article'
import About from './pages/About'
import Project from './pages/Project'
import Service from './pages/Service'
import Dash from './pages/Dash'


import DashHome from './containers/Dash/DashHome'
import Services from './containers/Dash/Services'
   import ServiceDash from './containers/Dash/Services/ServiceDash'
   import NewService from './containers/Dash/Services/NewService'
   import RemoveService from './containers/Dash/Services/RemoveService'
   import EditService from './containers/Dash/Services/EditService'
import Projects from './containers/Dash/Projects'
  import ProjectDash from './containers/Dash/Projects/ProjectDash'
  import NewProject from './containers/Dash/Projects/NewProject'
  import RemoveProject from './containers/Dash/Projects/RemoveProject'
  import EditProject from './containers/Dash/Projects/EditProject'
  import ProjectImages from './containers/Dash/Projects/ProjectImages'
import Leads from './containers/Dash/Leads'
  import LeadsDash from './containers/Dash/Leads/LeadsDash'
  import NewLead from './containers/Dash/Leads/NewLead';
  import EditLead from './containers/Dash/Leads/EditLead';
  import RemoveLead from './containers/Dash/Leads/RemoveLead'
import Reviews from './containers/Dash/Reviews'
  import ReviewDash from './containers/Dash/Reviews/ReviewDash'
  import NewReview from './containers/Dash/Reviews/NewReview';
  import RemoveReview from './containers/Dash/Reviews/RemoveReview'
  import EditReview from './containers/Dash/Reviews/EditReview';

import Promotions from './containers/Dash/Promotions'
  import PromoDash from './containers/Dash/Promotions/PromoDash'
  import NewPromo from './containers/Dash/Promotions/NewPromo';
  import EditPromo from './containers/Dash/Promotions/EditPromo';
  import RemovePromo from './containers/Dash/Promotions/RemovePromo'

import Articles from './containers/Dash/Articles'
  import ArticleDash from './containers/Dash/Articles/ArticleDash'
  import NewArticle from './containers/Dash/Articles/NewArticle'
  import EditArticle from './containers/Dash/Articles/EditArticle'
  import RemoveArticle from './containers/Dash/Articles/RemoveArticle'

import Amplify, {AuthModeStrategyType} from 'aws-amplify'
import {useSyncDataStore} from './utils'

import awsconfig from './aws-exports'


import { Router} from "@reach/router"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import './styles/main.scss';

Amplify.configure({
  ...awsconfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }});

function App() {
  const isDataStoreReady = useSyncDataStore()

  if ( !isDataStoreReady) {
    return (
      <></>
    )
  }
  return (
   
      <Router>
        <Main path="/" />
        <About path="about" />
        <Article path="article/:articleSlug" />
        <Project path="project/:projectSlug" />
        <Service path="service/:serviceSlug" />
        <Dash path="dash">
            <DashHome path="/" />
            <Services path="services">
              <ServiceDash path="/" />
              <NewService path="new" />
              <RemoveService path="remove/:serviceId" />
              <EditService path="edit/:serviceId" />
            </Services>
            <Projects path="projects" >
              <ProjectDash path="/" />
              <NewProject path="new" />
              <RemoveProject path="remove/:projectId" />
              <EditProject path="edit/:projectId" />
              <ProjectImages path="images/:projectId" />
            </Projects>
            <Leads path="leads">
              <LeadsDash path="/" />
              <NewLead path="new" />
              <EditLead path="edit/:leadId"/>
              <RemoveLead path="remove/:leadId" />
            </Leads>
            <Reviews path="reviews">
              <ReviewDash path="/" />
              <RemoveReview path="remove/:reviewId" />
              <NewReview path="new" />
              <EditReview path="edit/:reviewId"/>
            </Reviews>
            <Promotions path="promos">
              <PromoDash path="/" />
              <NewPromo path="new" />
              <EditPromo path="edit/:promoId"/>
              <RemovePromo path="remove/:promoId" />
            </Promotions>
            <Articles path="articles">
                <ArticleDash path="/" />
                <NewArticle path="new" />
                <EditArticle path="edit/:articleId"/>
                <RemoveArticle path="remove/:articleId" />
            </Articles>
        </Dash>
      </Router>
      
  );
}

export default App;

/*
<Dash path="dash">
            <DashHome path="/" />
            <Services path="services">
              <ServiceDash path="/" />
              <NewService path="new" />
              <RemoveService path="remove/:serviceId" />
              <EditService path="edit/:serviceId" />
            </Services>
            <Projects path="projects" >
              <ProjectDash path="/" />
              <NewProject path="new" />
              <RemoveProject path="remove/:projectId" />
              <EditProject path="edit/:projectId" />
              <ProjectImages path="images/:projectId" />
            </Projects>
            <Leads path="leads">
              <LeadsDash path="/" />
              <NewLead path="new" />
              <EditLead path="edit/:leadId"/>
              <RemoveLead path="remove/:leadId" />
            </Leads>
            <Reviews path="reviews">
              <ReviewDash path="/" />
              <RemoveReview path="remove/:reviewId" />
              <NewReview path="new" />
              <EditReview path="edit/:reviewId"/>
            </Reviews>
            <Promotions path="promos">
              <PromoDash path="/" />
              <NewPromo path="new" />
              <EditPromo path="edit/:promoId"/>
              <RemovePromo path="remove/:promoId" />
            </Promotions>
            <Articles path="articles">
                <ArticleDash path="/" />
                <NewArticle path="new" />
                <EditArticle path="edit/:articleId"/>
                <RemoveArticle path="remove/:articleId" />
            </Articles>
        </Dash>
*/