import React, {useEffect, useState} from 'react';
import MainMenu from './Header'
import {Header, Icon, Table} from 'semantic-ui-react'
import {Link} from '@reach/router'

import { DataStore } from '@aws-amplify/datastore';
import { Article } from '../../models';

function ArticleThumbnail({article}){
    return <Table.Row>
    <Table.Cell>
      <Header as='h4'>
        <Header.Content>
        {article.tagline1}
        </Header.Content>
      </Header>
    </Table.Cell>
    <Table.Cell className="mobile hidden">{article.tagline2}</Table.Cell>
    <Table.Cell><Link  className='btn btn-primary'  to={'edit/'+article.id}><i className="fas fa-pen"></i> <span className="mobile hidden">Edit</span></Link>
    <Link className='btn btn-error' style={{color:"red"}} to={'remove/'+article.id}><i className="fa fa-times"></i> <span className="mobile hidden">Remove</span></Link></Table.Cell>
  </Table.Row>
}

export default function Articles({children, location}){
    const [article, setArticles] = useState([])
    useEffect(() => {
        getArticles();
    },[])
    const getArticles = async()=>{
        const models = await DataStore.query(Article);
        console.log(models)
        setArticles(models)
    }

    useEffect(() => {
      if(location.state && location.state.repull){
        getArticles();
      }
  }, [location.state])
    
    
    return <div  className="App page-wrap">
    <MainMenu />
    <div >
        <div className="fix-header" style={{height:83}}></div>
        <Header as='h2' icon textAlign='center'>
            <Icon name='newspaper'  />
            <Header.Content>Articles</Header.Content>
            </Header>
       {children}

       {/*Display Services*/}
       <Table basic='very' padded striped unstackable>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Tagline</Table.HeaderCell>
            <Table.HeaderCell className='mobile hidden'>Tagline 2</Table.HeaderCell>

            <Table.HeaderCell >Actions</Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
       {article.map((a,i)=><ArticleThumbnail key={"a_"+i} article={a} />)}
       </Table.Body>
       </Table>
       </div>
    </div>

}