import React, { useState, useEffect, useCallback } from 'react';
import { Header } from 'semantic-ui-react'
import MenuHeader from '../components/MenuHeader'
import useStore from "../store/useStore";
import { useParams } from '@reach/router'
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Footer from '../components/Footer'
import { DataStore } from 'aws-amplify';
import { Project, Image } from '../models';
import { getImageDims } from '../utils'
import { getImageUrl } from '../components/SmartImage'
import Aigle from 'aigle';
import '../styles/gallery.scss';

export default function ViewProject() {
    const params = useParams();
    const [projectModel, setProjectModel] = useState();
    const [projectImages, setProjectImages] = useState([]);
    const [ready, setReady] = useState(false)
    const getImageKeyUrl = useStore(state => state.getImageKeyUrl);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    // init one ref to store the future isotope object
    const isotope = React.useRef()


    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    useEffect(() => {
        getProject();
    }, [])


    const getProject = async () => {
        const toView = await DataStore.query(Project, a => a.slug("eq", params.projectSlug));

        if (toView.length > 0) {
            const images = await DataStore.query(Image, a => a.projectID("eq", toView[0].id));
            const viewImages = await Aigle.map(images, async i => {

                let imgurlsm = getImageUrl(i.imageKey, 400);
                let imgurlmd = getImageUrl(i.imageKey, 800);
                let imgurllg = getImageUrl(i.imageKey, 1024);
                let imgurlxlg = getImageUrl(i.imageKey, 1600);
                let dims = await getImageDims(imgurlsm)
                console.log(dims)
                let widthnum = dims.width;
                let heightnum = dims.height;
                return {
                    src: imgurlsm,
                    // srcSet: [
                    //     imgurlsm+" 500w",
                    //     imgurlmd+" 800w",
                    //     imgurllg+" 1024w",
                    //     imgurlxlg+" 1600w"
                    //   ],
                    source: {
                        download: imgurllg,
                        fullscreen: imgurllg,
                        regular: imgurlmd,
                        thumbnail: imgurlsm
                    },
                    sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
                    width: widthnum,
                    height: heightnum,
                    caption: i.title
                }

            })
            console.log(toView, images)
            setProjectModel(toView[0])
            setProjectImages(viewImages)
            setReady(true)
        }
    }





    if (!ready) return <></>;
    return <div className="App page-wrap">

        <MenuHeader />
        <div className="md-content">
            <div className="fix-header" style={{ height: 83 }}></div>

            <div className="hero__wrapper" style={{ position: 'relative', height: 200, backgroundImage: `url(${projectImages[0].src})` }}>
                <div className="hero__inner">
                    <div className="container">
                        <h1 className="hero__title">{projectModel.name}</h1>
                    </div>
                </div>
            </div>
            <section style={{ backgroundColor: 'white' }}>
                <div className="container md-section">
                    <Header >{projectModel.description}</Header>
                </div>
            </section>
            <div className="container md-section" >
                <Gallery photos={projectImages} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={projectImages}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </div>
        <Footer />
    </div>
}