import React from 'react';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import '../styles/admin.scss'

function Dash({children}) {
    return <AmplifyAuthenticator>
    <AmplifySignIn slot="sign-in" hideSignUp />
    {children}
    
</AmplifyAuthenticator>
}

export default Dash