import React, {useState} from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'
import {Link, useNavigate} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Promo } from '../../../models';
import {slugify} from '../../../utils'

export default function NewPromo(){
  const navigate = useNavigate();
  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")
  const [text, setText] = useState("")
  const onStartDateChange = (event, data) => setDateStart(data.value);
  const onEndDateChange = (event, data) => setDateEnd(data.value);
  const createPromo = async()=>{
    await DataStore.save(
      new Promo({
      dateStart:dateStart.toLocaleDateString(),
      dateEnd:dateEnd.toLocaleDateString(),
      text
    })
    );
    navigate('/dash/promos', { state:{repull:true }})
  }


    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/promos'>Cancel</Link></div>
      <Form>
      <Form.Field>
      <label>Start Date of Promotion</label>
      <SemanticDatepicker onChange={onStartDateChange} />
    </Form.Field>
    <Form.Field>
      <label>End Date of Promotion</label>
      <SemanticDatepicker onChange={onEndDateChange} />
    </Form.Field>
    <Form.Field>
      <label>Promotion Text</label>
      <TextArea  value={text} onChange={(e)=>setText(e.target.value)} placeholder='Enter review message' />
    </Form.Field>
    <button className="btn btn-primary" onClick={()=>createPromo()}>Create Promo</button>
  </Form></div>
}