import React, {useEffect, useRef, useState, useCallback} from "react";
import useStore from "../store/useStore";
import SmartImage from '../components/SmartImage';
import ScrollHelper from '../components/ScrollHelper'
import {Link} from '@reach/router'
// import { DataStore } from '@aws-amplify/datastore';
// import { Service } from '../../models';

import '../styles/services.scss';

export default function Services(){
    const services = useStore(state => state.services);
    const loadServices = useStore(state => state.loadServices);

    useEffect(() => {
        loadServices() 
      }, [])

      useEffect(() => {
       console.log("SERVICES CHANGED", services)
      }, [services])



//console.log(getServices())
    return <ScrollHelper identifier="services"><section className="md-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
                
                
                <div className="sec-title sec-title__lg-title md-text-center">
                    <h2 className="sec-title__title">Our services</h2><span className="sec-title__divider"></span>
                </div>
                
            </div>
        </div>
        <div className="row row-eq-height">
            {services.map(service=>
                <div key={service.id} className="col-sm-6 col-md-6 col-lg-4 ">
                
                
                    <div className="services">
                    {service.image && <SmartImage className="services__img" imageKey={service.image.imageKey} background />}
                        
                        <h2 className="services__title"><a href="#">{service.name}</a></h2>
                        <div className="services__desc">{service.description}</div>
                        
                        
                        <Link to={"service/"+service.slug} className="btn btn btn-primary btn-custom" href="#">Read more
                        </Link>
                        
                    </div>
                    
                </div>
                
                )}
            
            
        </div>
    </div>
</section>
</ScrollHelper>
}