// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Article, Image, Service, Review, Project, Lead, ProjectRequest, Promo } = initSchema(schema);

export {
  Article,
  Image,
  Service,
  Review,
  Project,
  Lead,
  ProjectRequest,
  Promo
};