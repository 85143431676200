import React from 'react';
import {Header, Statistic, Segment, Image, Icon } from 'semantic-ui-react'
import MenuHeader from '../components/MenuHeader'
import Footer from '../components/Footer'
export default function About() {
    return <div className="App page-wrap">

        <MenuHeader />
        <div className="md-content">
            <div className="fix-header" style={{ height: 83 }}></div>

            <section className="md-section">
                <div>
                    <Header as='h2' icon textAlign='center'>
                        <Image
                            centered
                            style={{ width: 200 }}
                            size='large'
                            src='https://acgi-homes.s3.us-west-2.amazonaws.com/ACGI+Logo+Final.png'
                        />
                        <Header.Content>Andruszko Construction Group Inc.</Header.Content>

                        <Header.Subheader>14455 SW Sexton Mountain Drive,
                            Beaverton, OR 97008</Header.Subheader>
                        <Header.Subheader>bobby@acgihomes.com</Header.Subheader>
                    </Header>
                </div>

            </section>
            <section className="md-section" style={{ backgroundColor: '#fff', padding: '60px 0' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 ">
                            <div className="mb-30">


                                <div className="sec-title sec-title__lg-title">
                                    <h2 className="sec-title__title">Family Construction History &amp; Pedigree</h2><span className="sec-title__divider"></span>
                                </div>
                                <div>
                                    <Segment vertical>
                                        <strong>Great Grandfather - Giovanni Iaconetti - Little John</strong>
                                    </Segment>
                                    <Segment vertical>Migrated from Italy looking for work &amp; opportunities
                                        abroad. He worked during the great depression on the
                                        railroads &amp; he was a stonemason.</Segment>
                                </div>
                                <div>
                                    <Segment vertical>
                                        <strong>Grandfather - Bob O. Yaconetti</strong>
                                    </Segment>
                                    <Segment vertical>Owner of Yacon Construction, Master Builder of homes with a
                                        20 year tenure in Lake Oswego &amp; Portland Metro Area.</Segment>
                                </div>
                                <div>
                                    <Segment vertical>
                                        <strong>Father - Bob W. Andruszko</strong>
                                    </Segment>
                                    <Segment vertical>Owned Andy's Professional Painting for 10 years.
                                        Master Painter.</Segment>
                                </div>
                                <div>
                                    <Segment vertical>
                                        <strong>Bobby D. Andruszko</strong>
                                    </Segment>
                                    <Segment vertical>CEO/General Contractor. Specialty - Int./ext. residential & commercial
                                        painting. 3rd Generation Oregon Contractor.</Segment>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 justify-content-center">



                            <blockquote className="quote-02 about-quote justify-content-center md-text-center">
                                <Statistic>
                                    <Statistic.Value style={{ color: 'white' }}>12</Statistic.Value>
                                    <Statistic.Label style={{ color: 'white' }}>years in business</Statistic.Label>
                                </Statistic>

                                <div>
                                    <Segment vertical>
                                        <strong>ACGI- Andruszko Construction Group Inc.</strong>
                                    </Segment>
                                    <Segment vertical>CCB # 212838 Est. 05/16</Segment>
                                </div>
                                <div>
                                    <Segment vertical>
                                        <strong>Andruszko Painting LLC</strong>
                                    </Segment>
                                    <Segment vertical>CCB # 186124 Est. 03/09-05/16</Segment>
                                </div>

                            </blockquote>

                            <blockquote className="quote-02 about-quote justify-content-center md-text-center">
                                <div>
                                    <Segment vertical>
                                        <strong>Portland State University Alumni: Class of 2007</strong>
                                    </Segment>
                                    <Segment vertical>BA Degree: Communications</Segment>
                                    <Segment vertical>Minor Degree: Civic Leadership</Segment>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
            <section className="md-section container">
                <div className="row">
                    <div className="col">
                        <blockquote className="quote-02 about-quote justify-content-center md-text-center">
                            <div>
                                <Segment vertical>
                                    <strong>ACGI Services</strong>
                                </Segment>
                                <Segment vertical>14 Consecutive Years as a Journeymen
                                    Painter - Residential &amp; Commercial</Segment>
                                <Segment vertical>Kitchen/Bathroom/Basement Remodeling or New Construction</Segment>
                                <Segment vertical>Concrete</Segment>
                                <Segment vertical>Decks &amp; Fencing</Segment>
                                <Segment vertical>Demolition</Segment>
                                <Segment vertical>Doors, Trim &amp; Windows</Segment>
                                <Segment vertical>DryRot Repairs &amp; Install</Segment>
                                <Segment vertical>Framing</Segment>
                                <Segment vertical>Flooring</Segment>
                                <Segment vertical>Kitchen Cabinets</Segment>
                            </div>
                        </blockquote>
                    </div>
                    <div className="col">
                        <blockquote className="quote-02 about-quote justify-content-center md-text-center">
                            <div>
                                
                                <Segment vertical>Pressure Washing</Segment>
                                <Segment vertical>Residential Covered Porch</Segment>
                                <Segment vertical>Overhang/Pergolas</Segment>
                                <Segment vertical>Rough &amp; Finish Carpentry</Segment>
                                <Segment vertical>Sheetrock</Segment>
                                <Segment vertical>Siding</Segment>
                                <Segment vertical>Texture</Segment>
                                <Segment vertical>Tile</Segment>
                                <Segment vertical>Trim Packages- Interior/Exterior</Segment>
                                <Segment vertical>Under-deck drainage systems</Segment>
                                <Segment vertical>Vanity Installation</Segment>
                                <Segment vertical>Waste Management Services- Metro</Segment>
                            </div>
                        </blockquote>
                    </div>
                    <div className="col">
                        <Header as='h2'>Company Background</Header>
                        <p style={{ fontSize: 18 }}>I was introduced to the construction industry at the age of 8 by my father, Bob Andruszko. We

                            painted some vertical T111 at a neighbor's house together and after back rolling for 8 hrs I told
                            my dad, that was the hardest work I had ever done. He laughed and never forgot that and then he
                            asked me if I still wanted to be a painter? Of course I did, viola! 21 years later, <strong>Andruszko
                                Painting LLC</strong> was established in 2009 In 2016, We rebranded and restructured our business
                            into <strong>ACGI</strong> for a couple reasons, taxes &amp; name representation tailored more towards the services
                            our company offers our clients for the last 12 years in business.</p>
                        <p style={{ fontSize: 17 }}>During college, at PSU, I acquired 4 years of experience working for various unions, labor
                            companies &amp; contractors in Portland. I started my company with my tax returns from 2009 at
                            the height of the recession. I'm on site for every project that we are awarded. We offer free
                            estimates, in-house financing, interior design services, turn key solutions from our builder and
                            subcontractor teams.</p>
                    </div>
                </div>
            </section>



            <section className="md-section"  style={{ backgroundColor: '#fff', padding: '60px 0' }}>
                <div className="row">
                    <div className="col">
                        <Header as='h2' icon textAlign='center'>
                            <a target="_blank" href="https://www.yelp.com/biz/acgi-andruszko-construction-group-beaverton-2?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)" style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Icon name='yelp' size='massive' />
                                <Header.Content>Yelp</Header.Content>
                            </a>
                        </Header>
                    </div>
                    <div className="col">
                        <Header as='h2' icon textAlign='center'>
                            <a target="_blank" href="https://nextdoor.com/pages/andruszko-construction-group-inc-beaverton-or?init_source=copy_link_share" style={{ display: 'flex', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Image src="https://d19rpgkrjeba2z.cloudfront.net/static/gen/fe6b9b01b5aca5e163e08ecc131c3446.svg" size="big" style={{ width: 120 }} />

                            </a>
                        </Header>
                    </div>
                    <div className="col">
                        <Header as='h2' icon textAlign='center'>
                            <a target="_blank" href="https://www.facebook.com/Andruszkoconstructiongroupinc/" style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Icon name='facebook' size='massive' />
                                <Header.Content>Facebook</Header.Content>
                            </a>
                        </Header>
                    </div>
                    <div className="col">
                        <Header as='h2' icon textAlign='center'>
                            <a target="_blank" href="https://www.houzz.com/pro/bobby-andruszko" style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Icon name='houzz' size='massive' />
                                <Header.Content>Houzz</Header.Content>
                            </a>
                        </Header>
                    </div>
                    <div className="col">
                        <Header as='h2' icon textAlign='center'>
                            <a target="_blank" href="https://www.bbb.org/us/or/beaverton/profile/construction/andruszko-construction-group-inc-1296-1000054634" style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Image style={{ width: 120 }} src="https://www.brotherswindowcleaning.com/wp-content/uploads/BBB-Icon.png" size="medium" />
                                <Header.Content>BBB</Header.Content>
                            </a>
                        </Header>
                    </div>
                    <div className="col">
                        <Header as='h2' icon textAlign='center'>
                            <a target="_blank" href="https://mobile.twitter.com/ACGIHomes" style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Icon name='twitter' size='massive' />
                                <Header.Content>Twitter</Header.Content>
                            </a>
                        </Header>
                    </div>
                    <div className="col">
                        <Header as='h2' icon textAlign='center'>
                            <a target="_blank" href="https://www.instagram.com/acgihomes503/" style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Icon name='instagram' size='massive' />
                                <Header.Content>Instagram</Header.Content>
                            </a>
                        </Header>
                    </div>
                </div>
            </section>
        </div>

        <Footer />
    </div>
}