import React, {useState, useEffect} from 'react';
import { Header } from 'semantic-ui-react'
import useStore from '../store/useStore'
import {useParams, Link} from '@reach/router'
import MenuHeader from '../components/MenuHeader'
import Footer from '../components/Footer'
import { DataStore } from 'aws-amplify';
import { Service, ProjectService, Image as ImageModel, Project } from '../models';
import { getImageUrl } from '../components/SmartImage'
import {find, filter, includes} from 'lodash'
import SmartImage from '../components/SmartImage'
export default function ViewService() {
    const params = useParams();
    const services = useStore(state => state.services);
    const projects = useStore(state => state.projects);
    const getServices = useStore(state => state.getServices);
    

    const [ready, setReady] = useState(false)
    useEffect(() => {
        getServices();
    }, [])

    // const getService = async () => {
    //     const toView = await DataStore.query(Service, a => a.slug("eq", params.serviceSlug));
    //     if (toView.length > 0) {
    //         const pS = await DataStore.query(ProjectService, ps => ps.serviceID("eq", toView[0].id));
    //         const projectImages = await DataStore.query(ImageModel, i => i.projectID("eq", pS[0].projectID));
    //         const featuredImage = projectImages[0]
    //         console.log(toView)

    //         setServiceModel(toView[0])
    //         setFeaturedImage(featuredImage)
    //         setProjects(pS)
    //         setReady(true)
    //     }
    // }

    const serviceModel = find(services, ['slug', params.serviceSlug]);
    const viewProjects = filter(projects, p=>includes(p.services, serviceModel.id));
    console.log(serviceModel, viewProjects)
    if (services.length < 1) return <></>;
    return <div className="App page-wrap">

        <MenuHeader />
        <div className="md-content">
            <div className="fix-header" style={{ height: 83 }}></div>

            <div className="hero__wrapper" style={{ position: 'relative', height: 200, backgroundImage: `url(${getImageUrl(serviceModel.imageKey, 1024)})` }}>
                <div className="hero__inner">
                    <div className="container">
                        <h1 className="hero__title">{serviceModel.name}</h1>

                    </div>
                </div>
            </div>
            <section style={{backgroundColor:'white'}}>
                <div className="container md-section">
                <Header >{serviceModel.description}</Header>
                </div>
                </section>
            <div className="container md-section">
            <div className="row">
            <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
                
                
                <div className="sec-title sec-title__lg-title md-text-center">
                    <h2 className="sec-title__title">Featured projects where we performed this service</h2><span className="sec-title__divider"></span>
                </div>
                
            </div>
        </div>
                <div className="row row-eq-height">
            {viewProjects.length>0 && viewProjects.map(project=>
                <div key={project.id} className="col-sm-6 col-md-6 col-lg-4 ">
                
                
                    <div className="services">
                        <SmartImage className="services__img" imageKey={project.primaryImage.imageKey} background />
                        <h2 className="services__title"><a href="#">{project.name}</a></h2>
                        <div className="services__desc">{project.description}</div>
                        
                        
                        <Link to={"/project/"+project.slug} className="btn btn btn-primary btn-custom" href="#">Read more
                        </Link>
                        
                    </div>
                    
                </div>
                
                )}
            
            
        </div>

            </div>
        </div>
        <Footer />
    </div>
}