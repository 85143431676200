import React, {useState} from 'react';
import useStore from "../store/useStore";
import {Icon, Header} from 'semantic-ui-react'
import '../styles/header.scss';

function MenuHeader() {
    const [menuActive, setMenuActive] = useState(false)
    const currentSection = useStore(state => state.currentSection);


    return <header className="header header-fixheight header--fixed">
        <div className="container">
            <div className="header__inner">
                <div className="header-logo"><a href="/"><img src="https://acgi-homes.s3-us-west-2.amazonaws.com/logo+inverted.png" alt="" /></a></div>

                <nav className="raising-nav">

                    <ul className={"raising-menu " + (menuActive ? " active" :"")}>
                        <li className={currentSection === "home"?"current":""}><a href="/#home">Home</a>
                        </li>
                        <li className={currentSection === "about"?"current":""}><a href="/#about">About</a>
                        </li>
                        <li className={currentSection === "services"?"current":""}><a href="/#services">services</a>
                        </li>
                        <li className={currentSection === "gallery"?"current":""}><a href="/#gallery">gallery</a>
                        </li>
                        <li className={currentSection === "testimonial"?"current":""}><a href="/#testimonial">testimonial</a>
                        </li>
                        <li className={currentSection === "contact"?"current":""}><a href="/#contact">contact us</a>
                        </li>
                    </ul>

                    <div className="navbar-toggle" onClick={()=>setMenuActive(!menuActive)}><i className="fa fa-bars"></i></div>
                </nav>

                <div style={{fontSize:22,display:'flex', flexDirection:'row'}}>
                <a href="tel:5034311194" style={{ display: 'flex',marginRight:5, flexDirection: 'column', textDecoration:'none', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Icon name='phone' />
                    </a>
                    <a target="_blank" href="https://www.yelp.com/biz/acgi-andruszko-construction-group-beaverton-2?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)" style={{ display: 'flex',marginRight:5, flexDirection: 'column', textDecoration:'none', height: 60, justifyContent: 'center', alignItems: 'center' }}>
                                <Icon name='yelp' />
                    </a>
                    <a target="_blank" href="https://www.facebook.com/Andruszkoconstructiongroupinc/" style={{ display: 'flex', flexDirection: 'column', textDecoration:'none', height: 60, justifyContent: 'center', marginRight:5,alignItems: 'center' }}>
                        <Icon name='facebook f' />
                    </a>
                    <a target="_blank" href="https://mobile.twitter.com/ACGIHomes" style={{ display: 'flex', flexDirection: 'column', textDecoration:'none', height: 60, justifyContent: 'center', marginRight:5,alignItems: 'center' }}>
                        <Icon name='twitter'  />
                    </a>
                    <a target="_blank" href="https://www.houzz.com/pro/bobby-andruszko" style={{ display: 'flex', flexDirection: 'column', textDecoration:'none', height: 60, justifyContent: 'center', marginRight:5,alignItems: 'center' }}>
                        <Icon name='houzz'  />
                    </a>
                </div>
            </div>
        </div>
    </header>

}

export default MenuHeader;