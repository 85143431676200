import React, { useEffect} from "react";
import Isotope from 'isotope-layout';
import useStore from "../store/useStore";
// import SmartImage from '../components/SmartImage';
import ScrollHelper from '../components/ScrollHelper'
import {random, inRange} from 'lodash';
import {Link} from '@reach/router'
import '../styles/gallery.scss';


export default function Gallery(){
    const projects = useStore(state => state.projects);
    const loadProjects = useStore(state => state.loadProjects);
    const getImageKeyUrl = useStore(state => state.getImageKeyUrl);
    // init one ref to store the future isotope object
  const isotope = React.useRef()
  // store the filter keyword in a state
//   const [filterKey, setFilterKey] = React.useState('*')
  useEffect(() => {
    console.log("PROJECTS CHANGED", projects)
   }, [projects])
   useEffect(() => {
    loadProjects() 
  }, [])
  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope('.grid__inner', {
        percentPosition: true,
      itemSelector: '.grid-item',
      masonry: {
        // set to the element
        columnWidth: '.grid-sizer'
      }
    })
    // cleanup
    return () => isotope.current.destroy()
  }, [projects])


    const galleryItem = (project)=>{
        console.log("GIProject", project)
        var type = "";
        var size = 400;
        var promoImage = project.image.imageKey;
        var rangeNum = random(10);
        if(inRange(rangeNum, 2)){type = "large"; size=800};
        if(inRange(rangeNum,2,5)){type = "wide"; size = 600};
        
        

        return <div className={"grid-item " + type} key={`gi_${project.id}`}>
        <div className="grid-item__inner">
            <div className="grid-item__content-wrapper">
                
                
                <div className="gallery gallery--grid">
                    <div className="gallery__image bg-image" style={{backgroundImage: `url(${getImageKeyUrl(promoImage, {size})})`}}>
                        <img src={getImageKeyUrl(promoImage)} alt={project.name}/>
                        <Link to={"project/"+project.slug} className="gallery__overlay mfp-image" href="#" data-effect="mfp-zoom-in" title={project.name}>
                            <div className="md-tb">
                                <div className="md-tb__cell md-text-center"><i className="fa fa-search"></i></div>
                            </div></Link>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    }

    return <ScrollHelper identifier="gallery"><section className="md-section" style={{backgroundColor:"#fff"}}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
                
                
                <div className="sec-title sec-title__lg-title md-text-center">
                    <h2 className="sec-title__title">Project Gallery</h2><span className="sec-title__divider"></span>
                </div>
                
            </div>
        </div>
        
        
        <div className="gallery-wrap gallery-album" >
            <div className="grid-css grid-css--grid" data-col-lg="4" data-col-md="2" data-col-sm="2" data-col-xs="1" data-gap="30">
                <div className="grid__inner" >
                    <div className="grid-sizer"></div>
                    
                    
                    
                    
                    
                    {projects.length > 0 && projects.map(proj=>galleryItem(proj))}
                    
                </div>
            </div>
        </div>
        
    </div>
</section>
</ScrollHelper>
}