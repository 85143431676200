// This class handles all queries and mutations to the blockchain.
// This is setup as a context provider to handle peer connection states.

import create from 'zustand'
import produce from 'immer';

import ServicesSlice from './actions.services';
import UISlice from './actions.UI';
// Log every time state is changed
const log = config => (set, get, api) => config(args => {
  //console.log("  applying", args)
  set(args)
  //console.log("  new state", get())
}, get, api)

// Turn the set method into an immer proxy
const immer = config => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === 'function'
      ? produce(partial)
      : partial
  return set(nextState, replace)
}, get, api)

const useStore = create(log(immer((set, get) => ({


  // currentScreen:"rooms",
  // setCurrentScreen: (screenName) => set(produce(state => {
  //   state.currentScreen = screenName
  // }))
 //Separate all module states into slices
  ...ServicesSlice(set,get),
  ...UISlice(set,get),
  getImageKeyUrl:(imageKey, options={})=>{
      if(imageKey === undefined) return;
        var CloudFrontUrl = "https://d2xyzyk661n2vc.cloudfront.net";
        
        const imageRequest = JSON.stringify({
            "bucket": "acgiimagestorageprod-prod",
            "key": imageKey,
            "edits": {
                "resize": {
                    "width": options.size || 400,
                    "fit": options.fit || "cover"
                }
            }
        })
        const url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
        return url;
  }
}))))

export default useStore;