import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form, Header } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore, Storage } from 'aws-amplify';
import { Project, Image as ImageModel } from '../../../models';
import {slugify} from '../../../utils'
import RUG, {DragArea, Card} from 'react-upload-gallery'
import * as path from 'path'
import {getImageDims} from '../../../utils'
import 'react-upload-gallery/dist/style.scss'; 
import '../../../styles/projectStyles.scss';


export default function EditProject(){
  const navigate = useNavigate();
  const params = useParams();
  const [projectModel, setProjectModel] = useState()
  const [images, setImages] = useState([])
  const [ready, setReady] = useState(false)

  useEffect(() =>{
    getProject();
  },[])

  const getProject = async() =>{
    const baseProject = await DataStore.query(Project, params.projectId);
    const projectImages = await DataStore.query(ImageModel, i=>i.projectID("eq", baseProject.id))
    setProjectModel(baseProject)
    setImages(projectImages)
    setReady(true)
  }

  

const storeImage = async({file, key, order})=>{
  const dims = await getImageDims(file);
  console.log("IMAGE DIMENSIONS",dims)
  const imageModel = new ImageModel({
    title:"",
		imageKey: key,
    order:order,
    primary:false,
    width:dims.width,
    height:dims.height,
    projectID: projectModel.id
	})
  console.log("IMAGE MODEL",imageModel)
  await DataStore.save(imageModel)
}

const uploadRequest = async({
  uid,
  file,
  data, // blob
  send,
  action,
  headers,
  onProgress,
  onSuccess,
  onError
}) => {
  const fileext = path.extname(file.name);
  const response = await Storage.put(uid+fileext, file, {
    contentType: file.type,
    resumable: true,
    completeCallback: (event) => {
        console.log(`Successfully uploaded ${event.key}`);
        onSuccess(uid, {url:`https://acgiimagestorageprod-prod.s3.us-west-2.amazonaws.com/public/${uid+fileext}`})
        storeImage({file, key:`public/${uid+fileext}`, order:parseInt(uid.split('-')[2])})
    },
    progressCallback: (progress) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        onProgress(uid, Math.round(progress.loaded / progress.total * 100));
    },
    errorCallback: (err) => {
        console.error('Unexpected error while uploading', err);
        onError(uid, {
          action,
          response:'Unexpected error while uploading: ' + err
        })
    }
})
console.log("response",response)

  return {
    abort() {
      // for cancels the request
    },
    //source: `https://acgiimagestorageprod-prod.s3.us-west-2.amazonaws.com/public/${uid}.png`
  }
}

const convertInitialState =()=>{
  return images.map(i=>{
    return {
      id: i.id,
      name:i.title || "",
      order: i.order,
      primary: i.primary,
      source:`https://acgiimagestorageprod-prod.s3.us-west-2.amazonaws.com/${i.imageKey}`
    }
  })
}

const deleteImage = async(image)=>{
  console.log(image)
  const modelToDelete = await DataStore.query(ImageModel, image.id);
  await DataStore.delete(modelToDelete);
}

const handleSortChange = async(images, { oldIndex, newIndex })=>{
  console.log(images, oldIndex, newIndex)
}

const changeImageTitle = async(image, value)=>{
  const modelToUpdate = await DataStore.query(ImageModel, image.id);
  await DataStore.save(ImageModel.copyOf(modelToUpdate, item => {
    item.title = value;
  }));
}

const handleImagePrimary = async(image)=>{
  console.log(image)
  const primaryToRemove = await DataStore.query(ImageModel, i => i.primary("eq", true));
  if(primaryToRemove.length > 0) {
    console.log(primaryToRemove[0])
    await DataStore.save(ImageModel.copyOf(primaryToRemove[0], item => {
      item.primary = false;
    }));
  }
  const modelToUpdate = await DataStore.query(ImageModel, image.id);
  console.log(modelToUpdate)
  await DataStore.save(ImageModel.copyOf(modelToUpdate, item => {
    item.primary = true;
  }));
}

if(!ready) return <></>
    return  <div className="workspace">
      
      <div className="admin-button-center">
        <Link className="btn btn-primary" to='/dash/projects'>Close</Link>
        <span style={{marginLeft:5, color:'red', fontWeight:'bold'}}>Beware: Everything done on this page is automatically saved</span>
        </div>
      <Header>{projectModel.name}</Header>
      <RUG 
      onSortEnd={handleSortChange}
      onDeleted={deleteImage}
      initialState={convertInitialState()}
      source={response => response.url}
      customRequest={uploadRequest}
      onSuccess={(e)=>console.log("success:", e)}
      >
        <DragArea style={{flexFlow:'wrap', display: 'flex'}}> 
  {
    (image) => {
      console.log(image)
      return <div style={{flexDirection:'row', display: 'inline-flex', position:'relative'}}>
        <Card image={image} />
        <div className={"image-primary " + (image.primary?"primary":"") } onClick={()=>handleImagePrimary(image)}><i className="fa fa-exclamation-circle"></i> </div>
        <div className="image-title mobile hidden"><input value={image.name} onChange={(e)=>changeImageTitle(image, e.target.value)}/> </div>
      </div>
    }
  }
  </DragArea>
        </RUG>
      
      </div>
}