import React, {useEffect, useState} from 'react';
import MainMenu from './Header'
import {Header, Icon, Table} from 'semantic-ui-react'

import {Link} from '@reach/router'

import { DataStore } from '@aws-amplify/datastore';
import { Review } from '../../models';

function ReviewThumbnail({review}){
    return <Table.Row>
    <Table.Cell>{review.author}</Table.Cell>
    <Table.Cell collapsing  className="mobile hidden">{review.text}</Table.Cell>
    <Table.Cell>
        <Link  className='btn btn-primary'  to={'edit/'+review.id}><i className="fas fa-pen"></i> <span  className="mobile hidden">Edit</span></Link>
    <Link className='btn btn-error' style={{color:"red"}} to={'remove/'+review.id}><i className="fa fa-times"></i> <span className="mobile hidden">Remove</span></Link>
    </Table.Cell>
  </Table.Row>
}

export default function Reviews({children, location}){
    const [reviews, setReviews] = useState([])
    useEffect(() => {
        getReviews();
    },[])
    const getReviews = async()=>{
        const models = await DataStore.query(Review);
        console.log(models)
        setReviews(models)
    }
    useEffect(() => {
        if(location.state && location.state.repull){
            getReviews();
        }
    }, [location.state])
    
    return <div  className="App page-wrap">
    <MainMenu />
    <div>
        <div className="fix-header" style={{height:83}}></div>
        <Header as='h2' icon textAlign='center'>
            <Icon name='star'  />
            <Header.Content>Reviews</Header.Content>
            </Header>
       {children}

       {/*Display Services*/}
       <Table basic='very' padded striped unstackable>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Author</Table.HeaderCell>
            <Table.HeaderCell collapsing className="mobile hidden">Text</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
       {reviews.map((r,i)=><ReviewThumbnail key={"r_"+i} review={r} />)}
       </Table.Body>
       </Table>
       </div>
    </div>

}