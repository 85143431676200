import React, { useState } from "react";
import ScrollHelper from '../components/ScrollHelper'
import ContactForm from '../components/ContactForm';

export default function ContactUs() {



    return <ScrollHelper identifier="contact"><section className="md-section" style={{ backgroundColor: "#fff", padding: "60px 0 0" }}>
        <div className="row">
            <div className="col-lg-12  ">


                <div className="sec-title sec-title__lg-title md-text-center">
                    <h2 className="sec-title__title">Contact us</h2><span className="sec-title__divider"></span>
                </div>

            </div>
        </div>
        <div className="container">
            <div className="row justify-content-center">
                
                <div className="col-lg-6">
                    <div className="main-content">
                        <div className="row">
                            <div className="col">
                                <ContactForm />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section className="md-section">
    <div className="container">
    <div className="row justify-content-center">
        <div className="col-lg-4">
                    <div className="sidebar">


                        <section className="widget">


                            <div className="sec-title">
                                <h2 className="sec-title__title">Contact &amp; Working hours</h2><span className="sec-title__divider"></span>
                            </div>




                            <div>
                                <div className="widget-contact__item"><span className="widget-contact__title">Tel:</span>
                                    <p className="widget-contact__text"><a href="tel:5034311194">503.431.1194</a></p>
                                </div>
                                <div className="widget-contact__item"><span className="widget-contact__title">email:</span>
                                    <p className="widget-contact__text"><a href="mailto:info@acgihomes.com">info@acgihomes.com</a></p>
                                </div>
                                <div className="widget-contact__item"><span className="widget-contact__title">Working Hours:</span>
                                    <p className="widget-contact__text">Mon - Sat: 9:00 am - 7:00pm </p>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
                <div className="col-lg-4">
                <p>Serving Oregon and Washington</p>

<div className="contact-gmap"><iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDwfuGBvM7H5STMUwc9eDoTbSY06cXELRE&q=place_id:ChIJVWqfm3xuk1QRdrgLettlTH0" width="100%" height="300" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe></div>

                    </div>
                </div>
                </div>
    </section>
    </ScrollHelper>
}