import React, {useState, useEffect, useRef} from "react";
import { DataStore } from '@aws-amplify/datastore';
import {Promo} from '../models'

import '../styles/quotebar.scss';

export default function QuoteBar(){
    const [promos, setPromos] = useState([])
    const [counter, setCounter] = useState(0);
    const timer = useRef(null);

    useEffect(() =>{
        getPromos();
    },[])

    
    

    useEffect(() => {
        timer.current = setInterval(() => setCounter((v) => v>=promos.length-1?0:v + 1), 6000);
        return () => {
            clearInterval(timer.current);
          };
   }, [promos])

    const getPromos = async()=>{
        const promos = await DataStore.query(Promo);
        //console.log(promos)
        setPromos(promos)
    }
    //console.log(counter)
    return <div className="cta-02">
    <div className="container">
        <div className="row">
            <div className="col-lg-9 ">
                {promos.map((p,i)=><h2 key={"promo_"+p.id} style={{height:0, margin:0, padding:0, fontSize:24}} className={"cta-02__title "+(counter === i?" fade-in":" fade-out")}>{p.text}</h2>)}
            </div>
            <div className="col-lg-3  md-text-right">
                
                
                <a className="btn btn-outline" href="#contact">Get a quote
                </a>
                
            </div>
        </div>
    </div>
</div>
}