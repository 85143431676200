import React from "react";

import AboutImage from '../imgs/2.jpg';
import ScrollHelper from '../components/ScrollHelper'
import '../styles/about.scss';

export default function AboutSection() {
    return <ScrollHelper identifier="about">
        <section className="md-section" style={{backgroundColor:'#fff',padding:'60px 0'}}>
    <div className="container">
        <div className="row">
            <div className="col-lg-6 ">
                <div className="mb-30">
                    
                    
                    <div className="sec-title sec-title__lg-title">
                        <h2 className="sec-title__title">About <span>ACGI</span></h2><span className="sec-title__divider"></span>
                    </div>
                    
                    <p>We stand behind our craftsmanship. If you are not completely satisfied with our work, you can simply contact us Here, and tell us how we can improve our services tailored to your request.</p>
<p>Our name is on our work, that’s why we are called ACGI. We stand by our name &amp; Rep. in the community &amp; we keep our word by providing quality craftsmanship every project. Our motto is “Our work speaks for itself on every project”.</p>
<p>Solid customer service skills, precise attention to detail, fair &amp; competitive pricing on personalized estimates tailored to the specifications we discuss on site.</p><br/><br/><a className="btn btn-primary btn-outline" href="/about">More about us</a>
                </div>
            </div>
            <div className="col-lg-6 ">
                
                
                <div className="about"><img src={AboutImage} alt=""/>
                    
                   
                    <blockquote className="quote-02 about-quote">
                        <p className="quote-02__text"><i className="fas fa-quote-left"></i>Bobby and his crew came out on short notice, and did fantastic work, for a very reasonable price. I will definitely be calling him out when I need additional work done! Thanks guys!!!</p>
                        
                        
                        <div className="authorbox">
                            <div className="authorbox__avartar" style={{backgroundImage: "url(https://unsplash.it/100)"}}><img src="https://unsplash.it/100" alt=""/></div>
                            <div className="authorbox__info">
                                <h5 className="authorbox__name">Kristine Lynch</h5>
                                <p className="authorbox__work">Painting</p>
                            </div>
                        </div>
                        
                    </blockquote>
                    
                </div>
                
            </div>
        </div>
    </div>
</section>
</ScrollHelper>
}