import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Project } from '../../../models';
import {slugify} from '../../../utils'

export default function RemoveProject(){
  const navigate = useNavigate();
  const params = useParams();
  const [projectModel, setProjectModel] = useState()

  useEffect(() =>{
    getProject();
  },[])

  const getProject = async() =>{
    const toRemove = await DataStore.query(Project, params.projectId);
    setProjectModel(toRemove)
  }

  const removeProject = async() =>{
    //const modelToDelete = await DataStore.query(Project, 123456789);
    DataStore.delete(projectModel);


    navigate('/dash/projects', { state: { repull:true } })

  }
if(!projectModel) return <></>;
return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/projects'>This is scary, go back</Link></div>
      <Form>
    <div style={{margin:10, fontSize:20, marginTop:30}}><strong style={{color:'red'}}>Are you sure you want to remove the project:</strong> {projectModel.name}  ?</div>
    <div>This will remove all connections this project has with any services, reviews and images. <strong> Images will be deleted.</strong></div>
    <br/>
    <button className="btn btn-primary" onClick={()=>removeProject()}>Yeah I have better ones to show</button>
  </Form></div>
}