import produce from 'immer';
import { nanoid } from 'nanoid';
import * as queries from '../graphql/queries';
import * as savedQueries from '../graphql/savedQueries';
import { API, graphqlOperation } from 'aws-amplify';
import { each, filter, map, keyBy, isEmpty, compact, includes } from 'lodash';

const uiStoreSlice = (set, get) => ({
  currentSection:"home",
  setCurrentSection:(identifier)=>{
    set(produce(state=>{
      state.currentSection = identifier;
    }))
  }

});


export default uiStoreSlice;