import React, {useEffect, useState} from 'react';
import MainMenu from './Header'

import {Link} from '@reach/router'
import {Header, Icon, Table} from 'semantic-ui-react'
import { DataStore } from '@aws-amplify/datastore';
import { Project } from '../../models';

function ProjectThumbnail({project}){
    return <Table.Row>
    <Table.Cell>
      <Header as='h4'>
        <Header.Content>
        {project.name}
        </Header.Content>
      </Header>
    </Table.Cell>
    <Table.Cell className="mobile hidden">{project.description}</Table.Cell>
    <Table.Cell error={!project.isLive} textAlign="center" verticalAlign="middle">{project.isLive?<i className="fas fa-check"></i>:<i className="fas fa-times"></i>}</Table.Cell>
    <Table.Cell className="nowrap">
      <Link  className='btn btn-primary'  to={'images/'+project.id}><i className="fas fa-image"></i> <span className="mobile hidden">Images</span></Link>
      <Link  className='btn btn-primary'  to={'edit/'+project.id}><i className="fas fa-pen"></i> <span className="mobile hidden">Edit</span></Link>
      <Link className='btn btn-error' style={{color:"red"}} to={'remove/'+project.id}><i className="fa fa-times"></i> <span className="mobile hidden">Remove</span></Link>
    </Table.Cell>
  </Table.Row>
}

export default function Projects({children, location}){
    const [projects, setProjects] = useState([])
    useEffect(() => {
        getProjects();
    },[])
    const getProjects = async()=>{
        const models = await DataStore.query(Project);
        console.log(models)
        setProjects(models)
    }
    useEffect(() => {
      if(location.state && location.state.repull){
        getProjects();
      }
  }, [location.state])
    
    return <>
        
    <div  className="App page-wrap">
    <MainMenu />
    <div >
        <div className="fix-header" style={{height:83}}></div>
        <Header as='h2' icon textAlign='center'>
            <Icon name='warehouse'  />
            <Header.Content>Projects</Header.Content>
            </Header>
       {children}

       {/*Display projects*/}
       <Table basic='very' padded striped unstackable>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell width={8} className="mobile hidden">Description</Table.HeaderCell>
            <Table.HeaderCell >isLive</Table.HeaderCell>
            <Table.HeaderCell >Actions</Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
       {projects.map((p,i)=><ProjectThumbnail key={"p_"+i} project={p} />)}
       </Table.Body>
       </Table>
       </div>
    </div>
</>

}