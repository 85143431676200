import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Promo } from '../../../models';
import {slugify} from '../../../utils'

export default function RemovePromo(){
  const navigate = useNavigate();
  const params = useParams();
  const [promoModel, setPromoModel] = useState()

  useEffect(() =>{
    getPromo();
  },[])

  const getPromo = async() =>{
    const toRemove = await DataStore.query(Promo, params.promoId);
    console.log(promoModel)
    setPromoModel(toRemove)
  }

  const removePromo = async() =>{
    //const modelToDelete = await DataStore.query(Promo, 123456789);
    DataStore.delete(promoModel);


    navigate('/dash/promos', { state:{repull:true }})

  }
if(!promoModel) return <></>;
return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/promos'>Cancel</Link></div>
      <Form>
    <div style={{margin:10, fontSize:20, marginTop:30}}><strong style={{color:'red'}}>Are you sure you want to remove the promotion:</strong> {promoModel.text}  ?</div>
    <br/>
    <button className="btn btn-primary" onClick={()=>removePromo()}>Remove Promo</button>
  </Form></div>
}