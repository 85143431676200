import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import {Link, useNavigate, useParams} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Service } from '../../../models';
import {slugify} from '../../../utils'

export default function RemoveService(){
  const navigate = useNavigate();
  const params = useParams();
  const [serviceModel, setServiceModel] = useState()

  useEffect(() =>{
    getService();
  },[])

  const getService = async() =>{
    const toRemove = await DataStore.query(Service, params.serviceId);
    setServiceModel(toRemove)
  }

  const removeService = async() =>{
    //const modelToDelete = await DataStore.query(Service, 123456789);
    DataStore.delete(serviceModel);


    navigate('/dash/services', { state: { serviceChange:true } })

  }
if(!serviceModel) return <></>;
return  <div  className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/services'>Cancel</Link></div>
      <Form>
    <div style={{margin:10, fontSize:20}}><strong style={{color:'red'}}>Are you sure you want to remove the service:</strong> {serviceModel.name}  ?</div>
    <div>This will remove all connections this service has with any projects, reviews and images.</div>
    <br/>
    <button className="btn btn-primary" onClick={()=>removeService()}>Yes, I don't like {serviceModel.name} anymore</button>
  </Form></div>
}