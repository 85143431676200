import React, {useState, useEffect} from "react";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import ScrollHelper from '../components/ScrollHelper'
import { DataStore } from 'aws-amplify';
import { Review } from '../models';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'

export default function Testimonials(){
    const [reviews, setReviews] = useState([]);

    useEffect(() => {loadReviews()},[]);

    const loadReviews = async() =>{
        const reviewresp = await DataStore.query(Review);
        setReviews(reviewresp);
    }
    if(reviews.length < 1) return <></>;
    return <ScrollHelper identifier="testimonial"><section className="md-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-xs-offset-0 col-sm-offset-0 col-md-offset-0 col-lg-offset-2 ">
                
                
                <div className="sec-title sec-title__lg-title md-text-center">
                    <h2 className="sec-title__title">Testimonials</h2><span className="sec-title__divider"></span>
                </div>
                
            </div>
        </div>
        </div>
        <Swiper className="swiper"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={30}
      slidesPerView={3}
      centeredSlides={true} 
      breakpoints={{"600":{"slidesPerView":1},"991":{"slidesPerView":3,"spaceBetween":30}}}
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}>
        {reviews.map((r,i)=> <SwiperSlide key={"srk_"+r.id} className="testimonial-item">
                    <blockquote className="quote">
                        <p>{r.text}</p>
                    </blockquote>
                    <div className="authorbox">
                        <div className="authorbox__avartar" style={{backgroundImage: `url(${r.authorImage})`}}><img src={r.authorImage} alt={r.author}/></div>
                        <div className="authorbox__info">
                            <h5 className="authorbox__name">{r.author}</h5>
                            <p className="authorbox__work">{}</p>
                        </div>
                    </div>
                </SwiperSlide>
        
        
        )}
            </Swiper>
   
        
    
</section>
</ScrollHelper>
}