import React, {useState, useEffect} from 'react';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react'
import {Link, useNavigate} from '@reach/router'
import { DataStore } from '@aws-amplify/datastore';
import { Review, Service } from '../../../models';
import TextareaAutosize from "react-textarea-autosize";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {slugify} from '../../../utils'
import styled from 'styled-components';
import {includes, reject, find} from 'lodash';


const ToggleButton = styled(Button)
`
  box-shadow:none !important;
  margin:2px !important;
  border-color:#f0542c !important;
  &.ui.button.selected {
    background-color:#f0542c !important;
    color:white !important;
    box-shadow:none !important;
    border-color:white !important;
  }
`


export default function NewReview(){
  const navigate = useNavigate();
  const [author, setAuthor] = useState("")
  const [date, setDate] = useState("")
  const [text, setText] = useState("")
  const [authorImage, setAuthorImage] = useState("")
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([]);
  useEffect(() => {
      getServices();
  },[])
  const getServices = async()=>{
      const models = await DataStore.query(Service);
      console.log(models)
      setServices(models)
  }

  const onDateChange = (event, data) => setDate(data.value);

  const createReview = async()=>{
    console.log(date)
    const review = await DataStore.save(
      new Review({
            author,
            date:date.toString(),
            text,
            authorImage
        })
    );
    selectedServices.map(ss=>{
        // const reviewservice = DataStore.save(
        //   new ReviewService({
        //     "reviewID": review.id,
        //     "serviceID": ss,
        //   })
        // );
      })
    navigate('/dash/reviews', {state:{repull:true}})
  }

  const toggleService = (id)=>{
    let service = find(services, {id});
    if(includes(selectedServices, id)){
      setSelectedServices(reject(selectedServices, function(s) {return s === id; }));
    } else {
      setSelectedServices([...selectedServices, service.id])
    }
  }
  
  const isSelected = (id)=>{
    if(includes(selectedServices, id)) return "selected"; else return "";
  }
    return  <div className="workspace">
      
      <div className="admin-button-center"><Link className="btn btn-primary" to='/dash/reviews'>Cancel</Link></div>
      <Form>
    <Form.Field>
      <label>Author of Review</label>
      <input value={author} onChange={(e)=>setAuthor(e.target.value)} placeholder='Enter author name' />
    </Form.Field>
    <Form.Field>
      <label>Date of Review</label>
      <SemanticDatepicker onChange={onDateChange} />
    </Form.Field>
   
    <Form.Field
              control={TextareaAutosize}
              label="Review Message"
              placeholder="Enter review message"
              onChange={e => setText(e.target.value)}
              value={text}
            />
    <Form.Field>
      <label>Paste Avatar Url (ex: https://houzz.com/...</label>
      <input value={authorImage} onChange={(e)=>setAuthorImage(e.target.value)} placeholder='Enter author avatar url' />
    </Form.Field>
    <Form.Field>
    <label>Services Performed</label>
              {services.map((service)=> <ToggleButton color='orange' compact inverted key={"serv_"+service.id} onClick={()=>toggleService(service.id)} className={isSelected(service.id)}>{service.name}</ToggleButton>)}
              </Form.Field>
    <button className="btn btn-primary" onClick={()=>createReview()}>Create Review</button>
  </Form></div>
}