import React, { useEffect, useState } from 'react';
import MainMenu from './Header'

import { Link } from '@reach/router'
import { Message } from 'semantic-ui-react'
import { DataStore } from '@aws-amplify/datastore';
import { Service } from '../../models';


export default function Services({ children }) {
    const [services, setServices] = useState([])
    useEffect(() => {
        getServices();
    }, [])
    const getServices = async () => {
        const models = await DataStore.query(Service);
        console.log(models)
        setServices(models)
    }


    return <>

        <div className="App page-wrap">
            <MainMenu />
            <div className="md-content container">
                <div className="fix-header" style={{ height: 90 }}></div>
                <Link to="/dash/services" style={{marginBottom:5, display:'flex'}}>
                    <Message
                    floating
                        icon='settings'
                        header='Services'
                        content='Base service table'
                    />
                </Link>
                <Link to="/dash/projects" style={{marginBottom:5, display:'flex'}}>
                    <Message
                    floating
                        icon='warehouse'
                        header='Projects'
                        content='Add & Manage Projects / Images'
                    />
                </Link>
                <Link to="/dash/leads" style={{marginBottom:5, display:'flex'}}>
                    <Message
                    floating
                        icon='users'
                        header='Leads'
                        content='Add & View Contacts from recorded from the contact form'
                    />
                </Link>
                <Link to="/dash/reviews" style={{marginBottom:5, display:'flex'}}>
                    <Message
                    floating
                        icon='star'
                        header='Reviews'
                        content='Add & edit reviews from various sources to add to your site'
                    />
                </Link>
                <Link to="/dash/promos" style={{marginBottom:5, display:'flex'}}>
                    <Message
                    floating
                        icon='tag'
                        header='Promotions'
                        content='Add & edit promotions'
                    />
                </Link>
                <Link to="/dash/articles" style={{marginBottom:5, display:'flex'}}>
                    <Message
                    floating
                        icon='newspaper'
                        header='Articles'
                        content='Add & edit articles to show up on the homepage'
                    />
                </Link>

            </div>
        </div>
    </>

}